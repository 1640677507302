<template>
  <div class="loading-page">
    <!--<div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>-->
    <figure>
      <div class="dot white"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </figure>
  </div>
</template>

<script>
  export default {
    name:'loading',
    data() {
      return {

      }
    }
  }
</script>

<style>
  /**=加载中*/
  .loading-page{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .loading-page .spinner {
    margin: 100px auto 0;
    width: 150px;
    text-align: center;
  }

  .loading-page .spinner > div {
    width: 30px;
    height: 30px;
    background-color: #67CF22;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
    animation: bouncedelay 1.4s infinite ease-in-out;
    /* Prevent first frame from flickering when animation starts */
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .loading-page .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .loading-page .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0.0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes bouncedelay {
    0%, 80%, 100% {
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 40% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
      }
  }
  .loading-page figure {
    position: absolute;
    margin: auto;
    top: 0; bottom: 0; left: 0; right: 0;
    width: 6.250em; height: 6.250em;
    animation: rotate 2.4s linear infinite;
  }
  .loading-page .white {
    top: 0; bottom: 0; left: 0; right: 0;
    background: white;
    animation: flash 2.4s linear infinite;
    opacity: 0;
  }
  .loading-page .dot {
    position: absolute;
    margin: auto;
    width: 2.4em; height: 2.4em;
    border-radius: 100%;
    transition: all 1s ease;
  }
  .loading-page .dot:nth-child(2) { top: 0; bottom: 0; left: 0; background: #FF4444; animation: dotsY 2.4s linear infinite; }
  .loading-page .dot:nth-child(3) { left: 0; right: 0; top: 0; background: #FFBB33; animation: dotsX 2.4s linear infinite; }
  .loading-page .dot:nth-child(4) { top: 0; bottom: 0; right: 0; background: #99CC00; animation: dotsY 2.4s linear infinite; }
  .loading-page .dot:nth-child(5) { left: 0; right: 0; bottom: 0; background: #33B5E5; animation: dotsX 2.4s linear infinite; }

  @keyframes rotate {
    0% { transform: rotate( 0 ); }
    10% { width: 6.250em; height: 6.250em; }
    66% { width: 2.4em; height: 2.4em; }
    100%{ transform: rotate(360deg); width: 6.250em; height: 6.250em; }
  }

  @keyframes dotsY {
    66% { opacity: .1; width: 2.4em; }
    77%{ opacity: 1; width: 0; }
  }
  @keyframes dotsX {
    66% { opacity: .1; height: 2.4em;}
    77%{ opacity: 1; height: 0; }
  }

  @keyframes flash {
    33% { opacity: 0; border-radius: 0%; }
    55%{ opacity: .6; border-radius: 100%; }
    66%{ opacity: 0; }
  }
</style>
