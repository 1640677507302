<template>
    <div class="payment-list padding-top-56" v-if="isFinish">
        <van-nav-bar title="资金支付审核记录" left-text="返回" @click-left="goBack" fixed></van-nav-bar>
        <van-tabs v-model="status" color="#1b7aff" title-active-color="#1b7aff" line-width="50%" @click="chooseStatus">
            <van-tab title="已通过" name="1">
                <van-dropdown-menu>
                    <van-dropdown-item title="项目名称" ref="project">
                        <van-cell v-for="item in projectList" :title="item.text" @click="chooseProject(item.value)">
                            <van-icon v-if="item.value == projectId" slot="right-icon" name="success" color="#1b7aff" style="line-height: inherit;"/>
                        </van-cell>
                    </van-dropdown-item>
                    <van-dropdown-item title="时间" ref="time">
                        <van-cell v-for="item in timeTypes" :title="item.text" @click="chooseTimeType(item.value)">
                            <van-icon v-if="item.value == timeType" slot="right-icon" name="success" color="#1b7aff" style="line-height: inherit;"/>
                        </van-cell>
                    </van-dropdown-item>
                </van-dropdown-menu>
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="loadData"
                        class="list"
                >
                    <van-cell-group v-for="item in list" @click="goDetail(item.id)">
                        <van-cell :title="item.projectName">
                            <el-icon slot="icon" class="el-icon-ali-icon-test" style="line-height: 24px;color: #1b7aff"></el-icon>
                            <span slot="default">付款:<span style="color: #dd2727;" v-text="item.yfkMoney?item.yfkMoney.toFixed(2):'0.00'"></span>元</span>
                        </van-cell>
                        <van-row type="flex" justify="space-between" style="padding: 8px 16px;color: #969799;font-size: 14px;line-height: 24px">
                            <van-col span="14">收款单位:<span v-text="item.spName"></span></van-col>
                            <van-col span="10" style="text-align: right"><span v-text="item.entDate"></span></van-col>
                        </van-row>
                    </van-cell-group>
                </van-list>
            </van-tab>
            <van-tab title="未通过" name="2">
                <van-dropdown-menu>
                    <van-dropdown-item title="项目名称" ref="project">
                        <van-cell v-for="item in projectList" :title="item.text" @click="chooseProject(item.value)">
                            <van-icon v-if="item.value == projectId" slot="right-icon" name="success" color="#1b7aff" style="line-height: inherit;"/>
                        </van-cell>
                    </van-dropdown-item>
                    <van-dropdown-item title="时间" ref="time">
                        <van-cell v-for="item in timeTypes" :title="item.text" @click="chooseTimeType(item.value)">
                            <van-icon v-if="item.value == timeType" slot="right-icon" name="success" color="#1b7aff" style="line-height: inherit;"/>
                        </van-cell>
                    </van-dropdown-item>
                </van-dropdown-menu>
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="loadData"
                        class="list"
                >
                    <van-cell-group v-for="item in list" @click="goDetail(item.id)">
                        <van-cell :title="item.projectName" >
                            <el-icon slot="icon" class="el-icon-ali-icon-test" style="line-height: 24px;color: #1b7aff"></el-icon>
                            <span slot="default">付款:<span style="color: #dd2727;" v-text="item.yfkMoney?item.yfkMoney.toFixed(2):'0.00'"></span>元</span>
                        </van-cell>
                        <van-row type="flex" justify="space-between" style="padding: 8px 16px;color: #969799;font-size: 14px;line-height: 24px">
                            <van-col span="14">收款单位:<span v-text="item.spName"></span></van-col>
                            <van-col span="10" style="text-align: right"><span v-text="item.entDate"></span></van-col>
                        </van-row>
                    </van-cell-group>
                </van-list>
            </van-tab>
        </van-tabs>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                userInfo:null,
                status:'1',
                projectId: '',
                timeType: '',
                page: 1,
                pageSize: 10,
                projectList: [
                    { text: '全部', value: '' },
                ],
                timeTypes: [
                    { text: '全部', value: '' },
                    { text: '最近一星期', value: '2' },
                    { text: '最近一个月', value: '3' },
                    { text: '最近三个月', value: '4' },
                    { text: '最近六个月', value: '5' },
                ],
                list: [],
                loading: false,
                finished: false,
            }
        },
        components:{
            Loading
        },
        methods:{
            goBack(){
                this.$router.replace({name:'index'})
            },
            chooseProject(value){
                this.projectId = value;
                this.$refs.project.toggle();
                this.page = 1;
                this.list = [];
                this.getPaymentList()
            },
            chooseTimeType(value){
                this.timeType = value;
                this.$refs.time.toggle();
                this.page = 1;
                this.list = [];
                this.getPaymentList()
            },
            async init(){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                await this.getProjectList();
                this.page = 1;
                await this.getPaymentList();
                this.isFinish = true;
            },
            async getProjectList(){
                this.$POST(this.$url.资金项目列表,{id:this.userInfo.id,userType:this.userInfo.userType}).then((resp)=>{
                    if(resp.success){
                        this.projectList = this.projectList.concat(resp.result)
                    }
                })
            },
            async getPaymentList(){
                this.loading = true;
                this.$POST(this.$url.资金列表,{
                    userType:this.userInfo.userType,
                    userId:this.userInfo.id,
                    status:this.status,
                    projectId:this.projectId,
                    timeType:this.timeType,
                    pageNumber:this.page,
                    pageSize:this.pageSize
                }).then((resp)=>{
                    if(resp.success){
                        this.list = this.list.concat(resp.result.content)
                        if((this.page * this.pageSize)<resp.result.totalElements){
                            this.finished = false;
                        }else{
                            this.finished = true;
                        }
                        this.loading = false;
                    }
                })
            },
            async chooseStatus(name, title){
                this.status = name;
                this.page = 1;
                this.list = [];
                this.getPaymentList();
            },
            async loadData(){
                if(this.list.length > 0){
                    this.page = this.page + 1;
                    this.getPaymentList();
                }
            },
            goDetail(id){
                this.$router.push({name:'check-payment',query:{id:id}})
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .payment-list{background-color: #f3f3f3;min-height: 100%}
</style>