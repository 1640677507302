<template>
    <div class="stock-delivery-detail padding-top-56" v-if="isFinish">
        <van-nav-bar title="送货单" left-text="返回" @click-left="goBack" fixed></van-nav-bar>
        <div v-if="stockDelivery">
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">送货单号:</van-col>
                <van-col span="14" v-text="stockDelivery.stockDeliveryCode"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">项目名称:</van-col>
                <van-col span="14" v-text="stockDelivery.projectName"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">下单日期:</van-col>
                <van-col span="14" v-text="stockDelivery.orderDate"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">拟送货日期:</van-col>
                <van-col span="14" v-text="stockDelivery.deliveryDate"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">实际送货日期:</van-col>
                <van-col span="14" v-text="stockDelivery.realDeliveryDate"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">状态:</van-col>
                <van-col span="14" v-if="stockDelivery.deliveryStatus == 100">新建</van-col>
                <van-col span="14" v-if="stockDelivery.deliveryStatus == 200">开始送货</van-col>
                <van-col span="14" v-if="stockDelivery.deliveryStatus == 300">材料员已验收</van-col>
                <van-col span="14" v-if="stockDelivery.deliveryStatus == 400">分包队伍已验收</van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">送货金额:</van-col>
                <van-col span="14" v-text="stockDelivery.deliveryAmt+'元'" class="money"></van-col>
            </van-row>
            <el-table
                    :data="stockDelivery.items"
                    border
                    style="width: 100%;margin-top: 10px;">
                <el-table-column
                        fixed
                        prop="materialName"
                        label="名称"
                        width="150"
                        sortable>
                </el-table-column>
                <el-table-column
                        prop="spsNo"
                        label="规格"
                        width="100"
                        sortable>
                </el-table-column>
                <el-table-column
                        prop="materialUnit"
                        label="单位"
                        width="100"
                        sortable>
                </el-table-column>
                <el-table-column
                        prop="brand"
                        label="品牌"
                        width="100"
                        sortable>
                </el-table-column>
                <el-table-column
                        prop="planDeliveryQty"
                        label="计划数量"
                        width="120" align="right"
                        sortable>
                </el-table-column>
                <el-table-column
                        prop="upDeliveryQty"
                        label="已送数量"
                        width="120" align="right"
                        sortable>
                </el-table-column>
                <el-table-column
                        prop="notUpDeliveryQty"
                        label="未送数量"
                        width="120" align="right"
                        sortable>
                </el-table-column>
                <el-table-column
                        prop="realDeliveryQty"
                        label="本次送货数量"
                        width="150" align="right"
                        sortable>
                </el-table-column>
                <el-table-column
                        prop="price"
                        label="送货单价"
                        width="150" align="right"
                        sortable>
                </el-table-column>
                <el-table-column
                        prop="upName"
                        label="使用部位"
                        width="150"
                        sortable>
                </el-table-column>
                <el-table-column
                        prop="remark"
                        label="备注"
                        width="150"
                        sortable>
                </el-table-column>
            </el-table>
            <van-row gutter="10" style="background-color: #ffffff;margin-top: 10px;padding-top: 10px;font-size: 12px;color: #333333;">
                <van-col span="5" class="right">照片:</van-col>
                <van-col span="19">
                    <van-uploader
                            :max-count="stockDelivery.fileList.length"
                            v-model="stockDelivery.fileList"
                            :deletable="false"
                    />
                </van-col>
            </van-row>
        </div>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                userInfo:null,
                stockDelivery:null,
                id:this.$route.query.id,
                billId:this.$route.query.billId,
            }
        },
        components:{
            Loading
        },
        methods:{
            goBack(){
                this.$router.replace({name:'bill-detail',query:{id:this.billId}})
            },
            async init(){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                await this.getStockDeliveryDetail();
                this.isFinish = true
            },
            async getStockDeliveryDetail(){
                this.$POST(this.$url.送货单详情+this.id,{}).then((resp)=>{
                    if(resp.success){
                        resp.result.items.forEach((item)=>{
                            item.planDeliveryQty = item.planDeliveryQty.toFixed(3)
                            item.upDeliveryQty = item.upDeliveryQty.toFixed(3)
                            item.notUpDeliveryQty = item.notUpDeliveryQty.toFixed(3)
                            item.realDeliveryQty = item.realDeliveryQty.toFixed(3)
                            item.price = item.price.toFixed(2)
                        })
                        resp.result.fileList.forEach((item)=>{
                            item.isImage = true;
                        })
                        this.stockDelivery = resp.result
                    }
                })
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .stock-delivery-detail{background-color: #f3f3f3;min-height: 100%;overflow: hidden}
    .stock-delivery-detail .line{background-color:#ffffff;height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #333333;}
    .stock-delivery-detail .right{text-align: right;color: #777777;}
    .stock-delivery-detail .auto-class{color: #777777;}
    .stock-delivery-detail .money{color: #FF4444;font-weight: 700;}
</style>