<template>
    <div class="password padding-top-56" v-if="isFinish">
        <van-nav-bar title="修改密码" left-text="返回" @click-left="goBack" fixed></van-nav-bar>
        <el-row>
            <el-col :xs="20" :offset="2">
                <el-form :model="passwordForm" :rules="rules" ref="passwordForm" label-position="left" label-width="110px">
                    <el-form-item label="账号" >
                        <el-input readonly v-model="userInfo.account" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="旧的密码" prop="oldPassword">
                        <el-input v-model="passwordForm.oldPassword" type="password" placeholder="必填" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="新的密码" prop="password">
                        <el-input v-model="passwordForm.password" type="password" placeholder="必填" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="rePassword">
                        <el-input v-model="passwordForm.rePassword" type="password" placeholder="必填" size="small"></el-input>
                    </el-form-item>
                    <el-button type="primary" size="small" style="width: 100%;" @click="register">修改</el-button>
                </el-form>
            </el-col>
        </el-row>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                userInfo:null,
                passwordForm:{
                    account:'',
                    openId:'',
                    oldPassword:'',
                    password:'',
                    rePassword:''
                },
                rules:{
                    oldPassword: [
                        { required: true, message: '旧密码不能为空', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '新密码不能为空', trigger: 'blur' }
                    ],
                    rePassword: [
                        { required: true, message: '确认密码不能为空', trigger: 'blur' },
                        { validator: (rule, value, callback) => {
                            if (value !== this.passwordForm.password) {
                                callback(new Error('两次输入密码不一致'))
                            } else {
                                callback()
                            }
                        }, trigger: 'blur' },
                    ]
                }
            }
        },
        components:{
            Loading
        },
        methods:{
            goBack(){
                this.$router.replace({name:'index'})
            },
            init(){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.passwordForm.account = this.userInfo.account;
                let openId = localStorage.getItem('openId')
                this.passwordForm.openId = openId;
                this.isFinish = true;
            },
            register(){
                this.$refs.passwordForm.validate((valid) => {
                    if (valid) {
                        this.$POST(this.$url.修改密码,this.passwordForm).then((resp)=>{
                            if(resp.success){
                                this.$dialog.alert({
                                    message: '密码修改成功,将跳转到登录界面!'
                                }).then(() => {
                                    localStorage.removeItem("userInfo")
                                    localStorage.removeItem("token")
                                    this.$router.replace({name:'login'})
                                });
                            }else{
                                this.$dialog.alert({
                                    message: resp.message
                                }).then(() => {
                                    this.$refs.passwordForm.resetFields();
                                });
                            }
                        })
                    } else {
                        return false;
                    }
                });
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .password{}
</style>