<template>
    <div class="register padding-top-56">
        <van-nav-bar v-if="registerForm.userType == 100" title="供应商注册" left-text="返回" @click-left="goBack" fixed></van-nav-bar>
        <van-nav-bar v-if="registerForm.userType == 200" title="分包商注册" left-text="返回" @click-left="goBack" fixed></van-nav-bar>
        <el-row>
            <el-col :xs="20" :sm="20" :offset="2">
                <el-form :model="registerForm" :rules="rules" ref="registerForm" label-position="left" label-width="110px">
                    <el-form-item label="姓名" prop="userName">
                        <el-input v-model="registerForm.userName" placeholder="必填" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="userPhone">
                        <el-input v-model="registerForm.userPhone" type="phone" placeholder="必填" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号码" prop="cardNo">
                        <el-input v-model="registerForm.cardNo" placeholder="必填" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="住址" prop="address">
                        <el-input v-model="registerForm.address" placeholder="必填" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="授权委托书上传">
<!--                        <div class="preview-div" v-for="item in registerForm.fileList">-->
<!--                            <el-image-->
<!--                                    style="width: 80px; height: 80px"-->
<!--                                    :src="item.url"-->
<!--                                    :preview-src-list="[item.url]">-->
<!--                            </el-image>-->
<!--                            <i class="el-icon-error" @click="deleteImage(item.id)"></i>-->
<!--                        </div>-->
<!--                        <el-upload-->
<!--                                v-if="registerForm.fileList.length < 1"-->
<!--                                action="/mm/upload/attachment"-->
<!--                                :show-file-list=false-->
<!--                                :on-success="uploadSuccess"-->
<!--                                list-type="picture-card"-->
<!--                                accept="image/*">-->
<!--                            <i class="el-icon-plus"></i>-->
<!--                        </el-upload>-->
                        <van-uploader
                                :max-count="1"
                                v-model="registerForm.fileList"
                                :after-read="uploadImage"
                                @delete="deleteImage"
                        />
                    </el-form-item>
                    <el-button type="primary" :disabled="btnDisabled" size="small" style="width: 100%;" @click="register">注册</el-button>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                btnDisabled: false,
                registerForm:{
                    userType:this.$route.query.userType,
                    userName:'',
                    userPhone:'',
                    cardNo:'',
                    address:'',
                    fileList:[]
                },
                rules:{
                    userName: [
                        { required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                    userPhone: [
                        { required: true, message: '请输入手机号码', trigger: 'blur' },
                        { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                    ],
                    cardNo: [
                        { required: true, message: '请输入身份证号码', trigger: 'blur' },
                        { min: 18, max:18, message: '身份证号码长度错误', trigger: 'blur' }
                    ],
                    address: [
                        { required: true, message: '请输入住址', trigger: 'blur' }
                    ]
                }
            }
        },
        methods:{
            goBack(){
                this.$router.replace({name:'login'})
            },
            init(){

            },
            register(){
                this.$refs.registerForm.validate((valid) => {
                    if (valid) {
                        this.btnDisabled = true;
                        if(this.registerForm.fileList.length == 0){
                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: '请上传授权委托书'
                            });
                            this.btnDisabled = false;
                            return false;
                        }
                        let fileIds = [];
                        this.registerForm.fileList.forEach((item)=>{
                            fileIds.push(item.id)
                        })
                        this.registerForm.fileIds = fileIds;
                        this.$POST(this.$url.注册,this.registerForm).then((resp)=>{
                            if(resp.success){
                                this.$dialog.alert({
                                    message: '注册成功，初始化密码为'+resp.result+',请尽快登录修改密码!'
                                }).then(() => {
                                    this.$router.replace({name:'login'})
                                });
                            }else{
                                this.$dialog.alert({
                                    message: resp.message
                                }).then(() => {
                                    this.registerForm.userPhone = '';
                                });
                            }
                            this.btnDisabled = false;
                        })
                    } else {
                        return false;
                    }
                });
            },
            uploadImage(file,detail){
                let fd = new FormData()
                fd.append('file', file.file)
                this.$UPLOAD(this.$url.上传文件, fd, {headers: {
                        'Content-Type': 'multipart/form-data'
                    }}).then(res => {
                    this.registerForm.fileList[detail.index].content = res.result.url;
                    this.registerForm.fileList[detail.index].id = res.result.id;
                }).catch(err => {
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: err
                    });
                })
            },
            deleteImage(file){
                this.$DELETE(this.$url.删除文件+file.id,null).then((resp)=>{
                    if(resp.success){

                    }
                })
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .register{}
</style>