<template>
    <div class="bill-single-detail padding-top-56" v-if="isFinish">
        <van-nav-bar title="送货通知单详情" left-text="返回" @click-left="goBack" fixed></van-nav-bar>
        <div v-if="stockOrder">
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">项目名称:</van-col>
                <van-col span="14" v-text="stockOrder.projectName"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">供应商:</van-col>
                <van-col span="14" v-text="stockOrder.spName"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">单据编号:</van-col>
                <van-col span="14" v-text="stockOrder.stockOrderCode"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">拟送货日期:</van-col>
                <van-col span="14" v-text="stockOrder.deliveryDate"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">送货状态:</van-col>
                <van-col span="14" v-if="stockOrder.deliveryStatus == 100">未送货</van-col>
                <van-col span="14" v-if="stockOrder.deliveryStatus == 200">已推送通知</van-col>
                <van-col span="14" v-if="stockOrder.deliveryStatus == 300">部分供货</van-col>
                <van-col span="14" v-if="stockOrder.deliveryStatus == 400">供货完毕</van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">备注:</van-col>
                <van-col span="14" v-text="stockOrder.remark"></van-col>
            </van-row>
            <el-table
                    :data="stockOrder.items"
                    border
                    style="width: 100%;margin-top: 10px;">
                <el-table-column
                        fixed
                        prop="materialName"
                        label="名称"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="spsNo"
                        label="规格"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="materialUnit"
                        label="单位"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="brand"
                        label="品牌"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="planDeliveryQty"
                        label="计划数量"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="upDeliveryQty"
                        label="已送数量"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="notUpDeliveryQty"
                        label="未送数量"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="upName"
                        label="使用部位"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="remark"
                        label="备注"
                        width="150">
                </el-table-column>
            </el-table>
        </div>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                userInfo:null,
                stockOrder:null,
                id:this.$route.query.id,
            }
        },
        components:{
            Loading
        },
        methods:{
            goBack(){
                this.$router.replace({name:'bill-detail',query:{id:this.id}})
            },
            async init(){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                await this.getBillDetail();
                this.isFinish = true
            },
            async getBillDetail(){
                this.$POST(this.$url.送货通知单详情+this.id,{}).then((resp)=>{
                    if(resp.success){
                        resp.result.items.forEach((item)=>{
                            item.planDeliveryQty = item.planDeliveryQty.toFixed(3)
                            item.upDeliveryQty = item.upDeliveryQty.toFixed(3)
                            item.notUpDeliveryQty = item.notUpDeliveryQty.toFixed(3)
                        })
                        this.stockOrder = resp.result
                    }
                })
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .bill-single-detail{background-color: #f3f3f3;min-height: 100%;overflow: hidden}
    .bill-single-detail .line{background-color:#ffffff;height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #333333;}
    .bill-single-detail .right{text-align: right;color: #777777;}
</style>