<template>
    <div class="check-payment padding-top-56" v-if="isFinish">
        <van-nav-bar title="资金支付审核" left-text="返回" @click-left="goBack" fixed></van-nav-bar>
        <div v-if="!isAuth">
            抱歉，您没有权限访问该页面！
        </div>
        <div v-else>
            <div v-if="userInfo && userInfo.userType == 300">
                <div v-if="!this.canShowButton('mobile-payment-view')">
                    抱歉，您没有权限访问该页面！
                </div>
                <div v-if="this.canShowButton('mobile-payment-view') && payment">
                    <van-row gutter="10" class="line" v-for="item in payment.summaryField">
                        <van-col span="7" offset="2" class="right"><span v-text="item[0]"></span>:</van-col>
                        <van-col span="13"  v-text="item[1]"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line-1">
                        <van-col span="24" @click="goDetail">查看详情</van-col>
                    </van-row>
                    <van-collapse v-model="activeFile" style="margin-top: 10px">
                        <van-collapse-item :title="'资金支付附件('+payment.attachmentQty+')'" name="file">
                            <van-cell-group v-for="item in payment.fileList">
                                <van-cell :title="item.originalName">
                                    <el-icon slot="icon" class="el-icon-ali-icon-test" style="line-height: 24px;color: #1b7aff"></el-icon>
                                </van-cell>
                            </van-cell-group>
                        </van-collapse-item>
                    </van-collapse>
                    <van-collapse v-model="auditFile" style="margin-top: 10px" v-if="paymentAudit">
                        <van-collapse-item :title="'审核列表('+auditCount+'/'+totalCount+')'" name="audit">
                            <van-cell-group v-for="item in paymentAudit.list" style="margin-bottom: 10px;">
                                <van-cell :title="'审批人：'+item.entName">
                                    <div slot="default">
                                        <van-tag mark type="primary" v-if="item.statusStr == '已签收'">{{item.statusStr}}</van-tag>
                                        <van-tag mark type="danger" v-if="item.statusStr == '不通过'">{{item.statusStr}}</van-tag>
                                        <van-tag mark type="success" v-if="item.statusStr == '通过'">{{item.statusStr}}</van-tag>
                                    </div>
                                </van-cell>
                                <van-row type="flex" justify="space-between" style="padding: 8px 16px;color: #969799;font-size: 14px;line-height: 24px">
                                    <van-col span="24">签收时间:<span v-text="item.receiveTime"></span></van-col>
                                </van-row>
                                <van-row type="flex" justify="space-between" style="padding: 8px 16px;color: #969799;font-size: 14px;line-height: 24px">
                                    <van-col span="24">审核时间:<span v-text="item.auditTime"></span></van-col>
                                </van-row>
                                <van-row type="flex" justify="space-between" style="padding: 8px 16px;color: #969799;font-size: 14px;line-height: 24px">
                                    <van-col span="24">审核意见:<span v-text="item.opinion"></span></van-col>
                                </van-row>
                            </van-cell-group>
                        </van-collapse-item>
                    </van-collapse>
                    <div style="margin-top: 10px;background-color: #ffffff;" v-if="this.canShowButton('mobile-payment-audit')">
                        <van-field
                                v-model="message"
                                rows="2"
                                autosize
                                border
                                label="审批意见"
                                type="textarea"
                                maxlength="200"
                                placeholder="请输入审批意见"
                                show-word-limit
                        />
                        <van-row type="flex" justify="center" style="padding: 10px" :gutter="15">
                            <van-col span="8"><van-button type="primary" size="small" style="width: 100%" @click="auditPayment(1)">通过</van-button></van-col>
                            <van-col span="8"><van-button type="danger" size="small" style="width: 100%" @click="auditPayment(2)">不通过</van-button></van-col>
                        </van-row>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="payment">
                    <van-row gutter="10" class="line" v-for="item in payment.summaryField">
                        <van-col span="7" offset="2" class="right"><span v-text="item[0]"></span>:</van-col>
                        <van-col span="13"  v-text="item[1]"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line-1">
                        <van-col span="24" @click="goDetail">查看详情</van-col>
                    </van-row>
                    <van-collapse v-model="activeFile" style="margin-top: 10px">
                        <van-collapse-item :title="'资金支付附件('+payment.attachmentQty+')'" name="file">
                            <van-cell-group v-for="item in payment.fileList">
                                <van-cell :title="item.originalName">
                                    <el-icon slot="icon" class="el-icon-ali-icon-test" style="line-height: 24px;color: #1b7aff"></el-icon>
                                </van-cell>
                            </van-cell-group>
                        </van-collapse-item>
                    </van-collapse>
                    <van-collapse v-model="auditFile" style="margin-top: 10px" v-if="paymentAudit">
                        <van-collapse-item :title="'审核列表('+auditCount+'/'+totalCount+')'" name="audit">
                            <van-cell-group v-for="item in paymentAudit.list" style="margin-bottom: 10px;">
                                <van-cell :title="'审批人：'+item.entName">
                                    <div slot="default">
                                        <van-tag mark type="primary" v-if="item.statusStr == '已签收'">{{item.statusStr}}</van-tag>
                                        <van-tag mark type="danger" v-if="item.statusStr == '不通过'">{{item.statusStr}}</van-tag>
                                        <van-tag mark type="success" v-if="item.statusStr == '通过'">{{item.statusStr}}</van-tag>
                                    </div>
                                </van-cell>
                                <van-row type="flex" justify="space-between" style="padding: 8px 16px;color: #969799;font-size: 14px;line-height: 24px">
                                    <van-col span="24">签收时间:<span v-text="item.receiveTime"></span></van-col>
                                </van-row>
                                <van-row type="flex" justify="space-between" style="padding: 8px 16px;color: #969799;font-size: 14px;line-height: 24px">
                                    <van-col span="24">审核时间:<span v-text="item.auditTime"></span></van-col>
                                </van-row>
                                <van-row type="flex" justify="space-between" style="padding: 8px 16px;color: #969799;font-size: 14px;line-height: 24px">
                                    <van-col span="24">审核意见:<span v-text="item.opinion"></span></van-col>
                                </van-row>
                            </van-cell-group>
                        </van-collapse-item>
                    </van-collapse>
                </div>
            </div>
        </div>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                isAuth:true,
                userInfo:null,
                payment:null,
                paymentAudit:null,
                auditCount:0,
                totalCount:0,
                activeFile:[],
                auditFile:[],
                message:'',
                id:this.$route.query.id
            }
        },
        components:{
            Loading
        },
        methods:{
            goBack(){
                this.$router.replace({name:'payment-list'})
            },
            goDetail(){
                this.$router.replace({name:'payment-detail',query:{id:this.id}})
            },
            async init(){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                if(this.userInfo.userType == 300){
                    if(this.canShowButton('mobile-payment-view')){
                        await this.getPayment();
                        if(this.isAuth){
                            await this.auditView();
                            await this.auditList();
                        }
                    }
                }else{
                    await this.getPayment();
                    await this.auditList();
                }
                this.isFinish = true
            },
            async getPayment(){
                this.$POST(this.$url.资金支付信息+this.id,{}).then((resp)=>{
                    if(resp.success){
                        this.payment = resp.result
                    }else{
                        if(resp.code == 410){
                            this.isAuth = false;
                        }
                    }
                })
            },
            async auditView(){
                this.$POST(this.$url.资金支付签收,{
                    id:this.id,
                    userType:this.userInfo.userType,
                    userId:this.userInfo.id,
                    userName:this.userInfo.name
                }).then((resp)=>{
                    if(resp.success){}
                })
            },
            async auditList(){
                this.$POST(this.$url.资金支付审核历史+this.id,{}).then((resp)=>{
                    if(resp.success){
                        this.paymentAudit = resp.result;
                        this.auditCount = resp.result.auditNumber;
                        this.totalCount = resp.result.totalNumber;
                    }
                })
            },
            auditPayment(status){
                if(status == 2){
                    if(!this.message){
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: '请填写审批意见'
                        });
                        return;
                    }
                }
                this.$dialog.confirm({
                    message: status == 1 ? '确定审核通过吗?' : '确定审核不通过吗?'
                }).then(() => {
                    this.$POST(this.$url.资金支付审核,{
                        id:this.id,
                        userId:this.userInfo.id,
                        auditResult:status,
                        opinion:this.message?this.message:'同意'
                    }).then((resp)=>{
                        if(resp.success){
                            this.$toast('操作成功')
                            this.message = '';
                            this.auditList();
                        }
                    })
                }).catch(()=>{

                });
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .check-payment{background-color: #f3f3f3;min-height: 100%;overflow: hidden}
    .check-payment .line{background-color:#ffffff;height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #333333;}
    .check-payment .line-1{background-color:#ffffff;height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #1b7aff;border-top: 1px solid #ebedf0;text-align: center}
    .check-payment .right{text-align: right;color: #777777;}
    .check-payment .auto-class{color: #777777;}
    .check-payment .money{color: #FF4444;font-weight: 700;}
</style>