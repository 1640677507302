<template>
    <div class="add-stock-delivery padding-top-56" v-if="isFinish">
        <van-nav-bar title="送货单" left-text="返回" @click-left="goBack" fixed>
            <template #right>
                <van-button type="primary" size="mini" @click="save">保存</van-button>
            </template>
        </van-nav-bar>
        <div v-if="stockDelivery">
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">送货单号:</van-col>
                <van-col span="14" v-text="stockDelivery.stockDeliveryCode"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">项目名称:</van-col>
                <van-col span="14" v-text="stockDelivery.projectName"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">下单日期:</van-col>
                <van-col span="14" v-text="stockDelivery.orderDate"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">拟送货日期:</van-col>
                <van-col span="14" v-text="stockDelivery.deliveryDate"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">实际送货日期:</van-col>
                <van-col span="10">
                    <span v-text="date?date:'选择日期'" @click="dateShow = true"></span>
                    <van-calendar v-model="dateShow" @confirm="chooseDate"/>
                </van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">状态:</van-col>
                <van-col span="14">新建</van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">送货金额:</van-col>
                <van-col span="14" v-text="totalAmt+'元'" class="money"></van-col>
            </van-row>
            <el-form :model="stockDelivery" ref="itemsForm">
                <el-table
                        :data="stockDelivery.items"
                        border
                        row-class-name="default-row"
                        style="width: 100%;margin-top: 10px;">
                    <el-table-column
                            fixed
                            prop="materialName"
                            label="名称"
                            width="150"
                            sortable>
                    </el-table-column>
                    <el-table-column
                            prop="spsNo"
                            label="规格"
                            width="100"
                            sortable>
                    </el-table-column>
                    <el-table-column
                            prop="materialUnit"
                            label="单位"
                            width="100"
                            sortable>
                    </el-table-column>
                    <el-table-column
                            prop="brand"
                            label="品牌"
                            width="100"
                            label-class-name="editColumn">
                        <template slot-scope="scope">
                            <van-field v-model="scope.row.brand" placeholder="" />
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="planDeliveryQty"
                            label="计划数量"
                            width="120" align="right"
                            sortable>
                    </el-table-column>
                    <el-table-column
                            prop="upDeliveryQty"
                            label="已送数量"
                            width="120" align="right"
                            sortable>
                    </el-table-column>
                    <el-table-column
                            prop="notUpDeliveryQty"
                            label="未送数量"
                            width="120" align="right"
                            sortable>
                    </el-table-column>
                    <el-table-column
                            label="本次送货数量"
                            width="150"
                            label-class-name="editColumn">
                        <template slot-scope="scope">
                            <van-field type="number" v-model="scope.row.currentQty" placeholder="请输入本次送货数量" @blur="checkQty(scope)"/>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="送货单价"
                            width="150"
                            label-class-name="editColumn">
                        <template slot-scope="scope">
                            <van-field type="number" v-model="scope.row.currentAmt" placeholder="请输入送货单价"  @blur="checkAmt(scope)"/>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="upName"
                            label="使用部位"
                            width="150"
                            sortable>
                    </el-table-column>
                    <el-table-column
                            prop="remark"
                            label="备注"
                            width="150"
                            sortable>
                    </el-table-column>
                </el-table>
            </el-form>
        </div>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import moment from 'moment'
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                userInfo:null,
                stockDelivery:null,
                totalAmt:'0.00',
                date:'',
                dateShow:false,
                id:this.$route.query.id,
                billId:this.$route.query.billId,
            }
        },
        components:{
            Loading
        },
        methods:{
            goBack(){
                this.$router.replace({name:'bill-detail',query:{id:this.billId}})
            },
            chooseDate(date) {
                this.dateShow = false;
                this.date = moment(date).format('YYYY-MM-DD');
            },
            checkQty(scope){
                if(!scope.row.currentQty){
                    return;
                }
                if(!/^(([1-9]\d{0,15})|0)(\.\d{0,3})?$/.test(scope.row.currentQty)){
                    scope.row.currentQty = scope.row.notUpDeliveryQty
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: '请输入正确的送货数量'
                    });
                    return;
                }
                let notUpDeliveryQty = this.$math.bignumber(scope.row.notUpDeliveryQty);
                // @2021-08-08 供应商创建送货单时，不允许超10%
                // let currentMaxQty = this.$math.number(this.$math.eval(`${notUpDeliveryQty} * 1.1`));
                let currentMaxQty = notUpDeliveryQty
                let currentQty = this.$math.number(scope.row.currentQty)
                if(currentQty > currentMaxQty){
                    scope.row.currentQty = notUpDeliveryQty
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: '本次送货数量不能超过未送数量'
                    });
                    return;
                }
                this.calcAmt();
            },
            checkAmt(scope){
                if(!scope.row.currentAmt){
                    return;
                }
                if(!/^(([1-9]\d{0,15})|0)(\.\d{0,2})?$/.test(scope.row.currentAmt)){
                    scope.row.currentQty = '0.000'
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: '送货单价不正确'
                    });
                    return;
                }
                this.calcAmt();
            },
            calcAmt(){
                this.totalAmt = 0;
                let totalAmt = this.$math.bignumber(this.totalAmt)
                this.stockDelivery.items.forEach((item)=>{
                    let currentQty = this.$math.number(item.currentQty)
                    let currentAmt = this.$math.number(item.currentAmt)
                    totalAmt = this.$math.add(totalAmt,this.$math.eval(`${currentQty} * ${currentAmt}`))
                })
                this.totalAmt = this.$math.number(totalAmt).toFixed(2)
            },
            async init(){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                await this.getBillDetail();
                this.isFinish = true
            },
            async getBillDetail(){
                this.$POST(this.$url.送货单详情+this.id,{}).then((resp)=>{
                    if(resp.success){
                        let items = [];
                        resp.result.items.forEach((item)=>{
                            if(item.notUpDeliveryQty > 0){
                                item.planDeliveryQty = item.planDeliveryQty.toFixed(3)
                                item.upDeliveryQty = item.upDeliveryQty.toFixed(3)
                                item.notUpDeliveryQty = item.notUpDeliveryQty.toFixed(3)
                                item.currentQty = item.realDeliveryQty.toFixed(3);
                                item.currentAmt = item.price.toFixed(2);
                                items.push(item);
                            }
                        })
                        resp.result.items = items;
                        this.stockDelivery = resp.result;
                        this.totalAmt = this.stockDelivery.deliveryAmt.toFixed(2);
                        this.date = this.stockDelivery.realDeliveryDate;
                        this.calcAmt();
                    }
                })
            },
            save(){
                if(!this.date){
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: '请填写实际送货日期'
                    });
                    return;
                }
                let qtyCount = 0;
                let amtCount = 0;
                this.stockDelivery.items.forEach((item)=>{
                    if('0.000' == item.currentQty){
                        qtyCount = qtyCount + 1;
                    }
                    if('0.00' == item.currentAmt){
                        amtCount = amtCount + 1;
                    }
                })
                if(qtyCount == this.stockDelivery.items.length && amtCount == this.stockDelivery.items.length){
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: '本次送货数量和送货单价都为0，无法进行送货'
                    });
                    return;
                }
                if(qtyCount != 0 || amtCount != 0){
                    this.$dialog.confirm({
                        message: '本次发货数量或发货单价存在小于或等于0，确定要保存吗?'
                    }).then(() => {
                        this.saveDelivery();
                    }).catch(()=>{
                        return;
                    });
                }else{
                    this.saveDelivery();
                }
            },
            saveDelivery(){
                let items = [];
                this.stockDelivery.items.forEach((item)=>{
                    if('0.000' != item.currentQty){
                        items.push({id:item.id,stockOrderItemId:item.stockOrderItemId,currentQty:item.currentQty,currentAmt:item.currentAmt});
                    }
                })
                this.$POST(this.$url.编辑送货单,{
                    userId:this.userInfo.id,
                    id:this.id,
                    date:this.date,
                    totalAmt:this.totalAmt,
                    items:items
                }).then((resp)=>{
                    if(resp.success){
                        this.$router.replace({name:'bill-detail',query:{id:this.billId}})
                    }else{
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: resp.message
                        });
                        return;
                    }
                })
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .add-stock-delivery{background-color: #f3f3f3;min-height: 100%;overflow: hidden}
    .add-stock-delivery .line{background-color:#ffffff;height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #333333;}
    .add-stock-delivery .right{text-align: right;color: #777777;}
    .add-stock-delivery .auto-class{color: #777777;}
    .add-stock-delivery .money{color: #FF4444;font-weight: 700;}
</style>