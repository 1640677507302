<template>
    <div class="bill-detail padding-top-56" v-if="isFinish">
        <van-nav-bar title="送货通知单" left-text="返回" @click-left="goBack" fixed>
            <template #right>
                <van-button type="default" size="mini" @click="goStockOrderPrint">打印</van-button>
            </template>
        </van-nav-bar>
        <div v-if="stockOrder">
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">项目名称:</van-col>
                <van-col span="14" v-text="stockOrder.projectName"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">单据编号:</van-col>
                <van-col span="14">
                    <span class="link" v-text="stockOrder.stockOrderCode" @click="goDetail(stockOrder.id)"></span>
                </van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">拟送货日期:</van-col>
                <van-col span="14" v-text="stockOrder.deliveryDate"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">下单日期:</van-col>
                <van-col span="14" v-text="stockOrder.orderDate"></van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">送货状态:</van-col>
                <van-col span="14" v-if="stockOrder.deliveryStatus == 100">未送货</van-col>
                <van-col span="14" v-if="stockOrder.deliveryStatus == 200">已推送通知</van-col>
                <van-col span="14" v-if="stockOrder.deliveryStatus == 300">部分供货</van-col>
                <van-col span="14" v-if="stockOrder.deliveryStatus == 400">供货完毕</van-col>
            </van-row>
            <van-row gutter="10" class="line">
                <van-col span="6" offset="2" class="right">备注:</van-col>
                <van-col span="14" v-text="stockOrder.remark"></van-col>
            </van-row>
        </div>
        <van-cell v-if="(userInfo&&userInfo.userType==100) && stockOrder.deliveryStatus != 400" :title="'送货单('+list.length+')'" style="margin: 10px 0;" @click="addStockDelivery">
            <el-icon slot="icon" class="el-icon-ali-liebiao" style="line-height: 24px;color: #1b7aff"></el-icon>
            <el-icon slot="right-icon" class="el-icon-ali-wuuiconxiangjifangda" style="line-height: 24px;color: #1b7aff"></el-icon>
        </van-cell>
        <van-cell v-else :title="'送货单('+list.length+')'" style="margin: 10px 0;">
            <el-icon slot="icon" class="el-icon-ali-liebiao" style="line-height: 24px;color: #1b7aff"></el-icon>
            <span slot="right-icon"></span>
        </van-cell>
        <van-cell-group v-for="item in list" @click="goDelivery(item)" style="margin: 10px;">
            <van-cell title="送货单" :value="item.statuText" value-class="status-color">
                <el-icon slot="icon" class="el-icon-ali-icon-test" style="line-height: 24px;color: #1b7aff"></el-icon>
            </van-cell>
            <van-row type="flex" justify="space-between" style="padding: 8px 10px;color: #969799;font-size: 14px;line-height: 24px">
                <van-col span="12"><span v-text="item.stockDeliveryCode" style="font-size: 12px;"></span></van-col>
                <van-col span="12" v-if="userInfo&&userInfo.userType==100" style="overflow: hidden;">
                    <van-button v-if="item.deliveryStatus == 100" type="info" size="mini" @click="startDelivery($event,item.id)" style="float: right">开始送货</van-button>
                    <van-button v-if="item.deliveryStatus == 100" type="default" size="mini" @click="deleteDelivery($event,item.id)" style="float: right;margin-right: 5px;">删除</van-button>
                    <van-button v-if="item.deliveryStatus != 100" type="info" size="mini" @click="goStockDeliveryPrint($event,item.id)" style="float: right">查看送货单</van-button>
                </van-col>
                <van-col span="12" v-if="userInfo&&userInfo.userType==300" style="overflow: hidden;">
                    <van-button v-if="item.deliveryStatus != 100" type="info" size="mini" @click="goStockDeliveryPrint($event,item.id)" style="float: right">查看送货单</van-button>
                </van-col>
            </van-row>
        </van-cell-group>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                userInfo:null,
                stockOrder:null,
                id:this.$route.query.id,
                list: [],
            }
        },
        components:{
            Loading
        },
        methods:{
            goBack(){
                this.$router.replace({name:'bill-list'})
            },
            goStockOrderPrint(){
                window.location.href = 'printStockOrder.html?id='+this.id;
            },
            async init(){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                await this.getBillDetail();
                await this.getStockDeliveryList();
                this.isFinish = true
            },
            async getBillDetail(){
                this.$POST(this.$url.送货通知单详情+this.id,{}).then((resp)=>{
                    if(resp.success){
                        this.stockOrder = resp.result
                    }
                })
            },
            async getStockDeliveryList(){
                this.$POST(this.$url.送货单集合+this.id,{}).then((resp)=>{
                    if(resp.success){
                        resp.result.forEach((item)=>{
                            if(item.deliveryStatus == 100){
                                item.statuText = '新建';
                            }
                            if(item.deliveryStatus == 200){
                                item.statuText = '开始送货';
                            }
                            if(item.deliveryStatus == 300){
                                item.statuText = '材料员已验收';
                            }
                            if(item.deliveryStatus == 400){
                                item.statuText = '分包队伍已验收';
                            }
                        })
                        this.list = resp.result
                    }
                })
            },
            goDetail(id){
                this.$router.replace({name:'bill-single-detail',query:{id:id}})
            },
            goDelivery(item){
                if(this.userInfo.userType == 100 && item.deliveryStatus == 100){
                    this.$router.replace({name:'edit-stock-delivery',query:{id:item.id,billId:this.id}})
                }else{
                    this.$router.replace({name:'stock-delivery-detail',query:{id:item.id,billId:this.id}})
                }
            },
            startDelivery(e,id){
                e.stopPropagation();
                this.$dialog.confirm({
                    message: '确定要开始送货吗?'
                }).then(() => {
                    this.$POST(this.$url.开始送货单+id,{}).then((resp)=>{
                        if(resp.success){
                            this.init();
                        }
                    })
                }).catch(()=>{

                });
            },
            deleteDelivery(e,id){
                e.stopPropagation();
                this.$dialog.confirm({
                    message: '确定要删除吗?'
                }).then(() => {
                    this.$POST(this.$url.删除送货单+id,{}).then((resp)=>{
                        if(resp.success){
                            this.init();
                        }
                    })
                }).catch(()=>{

                });
            },
            addStockDelivery(){
                let canAdd = true;
                for (let i = 0; i < this.list.length; i++) {
                    if(this.list[i].deliveryStatus == 100){
                        canAdd = false;
                        break;
                    }
                }
                if(!canAdd){
                    this.$dialog.alert({
                        message: '存在还没送货的单据,请先送货!'
                    }).then(() => {

                    });
                    return;
                }
                this.$router.replace({name:'add-stock-delivery',query:{id:this.id}})
            },
            goStockDeliveryPrint(e,id){
                e.stopPropagation();
                window.location.href = 'printStockDelivery.html?id='+id;
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .bill-detail{background-color: #f3f3f3;min-height: 100%;overflow: hidden}
    .bill-detail .line{background-color:#ffffff;height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #333333;}
    .bill-detail .right{text-align: right;color: #777777;}
    .bill-detail .link{color: #1b7aff;}
    .bill-detail .status-color{color: #fd8d42;}
</style>