<template>
    <div class="check-plan padding-top-56" v-if="isFinish">
        <van-nav-bar title="需用计划审批" left-text="返回" @click-left="goBack" fixed></van-nav-bar>
        <div v-if="!isAuth">
            抱歉，您没有权限访问该页面！
        </div>
        <div v-else>
            <div v-if="!this.canShowButton('mobile-plan-view')">
                抱歉，您没有权限访问该页面！
            </div>
            <div v-if="plan && this.canShowButton('mobile-plan-view')">
                <van-row gutter="10" class="line">
                    <van-col span="7" offset="2" class="right">项目名称:</van-col>
                    <van-col span="13" v-text="plan.projectName"></van-col>
                </van-row>
                <van-row gutter="10" class="line">
                    <van-col span="7" offset="2" class="right">计划编号:</van-col>
                    <van-col span="13" v-text="plan.planCode"></van-col>
                </van-row>
                <van-row gutter="10" class="line">
                    <van-col span="7" offset="2" class="right">分包队伍:</van-col>
                    <van-col span="13" v-text="plan.teamName"></van-col>
                </van-row>
                <van-row gutter="10" class="line">
                    <van-col span="7" offset="2" class="right">供应商:</van-col>
                    <van-col span="13" v-text="plan.spName"></van-col>
                </van-row>
                <van-row gutter="10" class="line">
                    <van-col span="7" offset="2" class="right">状态:</van-col>
                    <van-col span="13" v-if="plan.planStatus == 100">未上报</van-col>
                    <van-col span="13" v-if="plan.planStatus == 200">审批中</van-col>
                    <van-col span="13" v-if="plan.planStatus == 300">已审核</van-col>
                    <van-col span="13" v-if="plan.planStatus == 400">已退回</van-col>
                </van-row>
                <van-row gutter="10" class="line">
                    <van-col span="7" offset="2" class="right">编制人:</van-col>
                    <van-col span="13" v-text="plan.entName"></van-col>
                </van-row>
                <van-row gutter="10" class="line">
                    <van-col span="7" offset="2" class="right">编制日期:</van-col>
                    <van-col span="13" v-text="plan.entDate"></van-col>
                </van-row>
                <van-row gutter="10" class="line-area">
                    <van-col span="7" offset="2" class="right">备注:</van-col>
                    <van-col span="13" v-text="plan.remark" style="line-height: 20px;margin: 10px 0;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;"></van-col>
                </van-row>
                <van-collapse v-model="activeItems" style="margin-top: 10px">
                    <van-collapse-item title="需用计划明细" name="items">
                        <el-table
                                :data="plan.items"
                                border
                                :cell-class-name="cellClassName"
                                style="width: 100%;">
                            <el-table-column
                                    fixed
                                    prop="materialType"
                                    label="材料分类"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="materialCode"
                                    label="材料编码"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="materialName"
                                    label="材料名称"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="spsNo"
                                    label="规格"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="materialUnit"
                                    label="单位"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="applyAmt"
                                    label="申报量"
                                    width="100">
                            </el-table-column>
<!--                            <el-table-column-->
<!--                                    prop=""-->
<!--                                    label="公司结余数量"-->
<!--                                    width="150">-->
<!--                            </el-table-column>-->
                            <el-table-column
                                    v-if="nextApprover && nextApprover.isAllowEditAuditAmt == 0"
                                    label="审批数量"
                                    width="100"
                                    label-class-name="editColumn">
                                <template slot-scope="scope">
                                    <van-field type="number" :class="scope.row.totalAuditQty > scope.row.budgetAmt ? 'borderColorRed':'borderColorNormal'" v-model="scope.row.auditAmt" placeholder="请输入审批数量" @blur="checkQty(scope)"/>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    v-else
                                    prop="auditAmt"
                                    label="审批数量"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    v-if="nextApprover && nextApprover.isAllowEditAuditAmt == 0"
                                    label="理论审批数量"
                                    width="120"
                                    label-class-name="editColumn">
                                <template slot-scope="scope">
                                    <van-field type="number" :class="scope.row.totalAuditQty > scope.row.budgetAmt ? 'borderColorRed':'borderColorNormal'" v-model="scope.row.theoryAuditAmt" placeholder="请输入理论审批数量" @blur="checkTheoryQty(scope)"/>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    v-else
                                    prop="theoryAuditAmt"
                                    label="理论审批数量"
                                    width="120">
                            </el-table-column>
                            <el-table-column
                                    prop="totalAuditQty"
                                    label="累计审批数量"
                                    width="150">
                            </el-table-column>
<!--                            <el-table-column-->
<!--                                    prop="srAmt"-->
<!--                                    label="累计领料量"-->
<!--                                    width="150">-->
<!--                            </el-table-column>-->
                            <el-table-column
                                    prop="budgetAmt"
                                    label="预算量"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="upName"
                                    label="使用部位"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="remark"
                                    label="备注"
                                    width="150">
                            </el-table-column>
                        </el-table>
                    </van-collapse-item>
                </van-collapse>
                <van-collapse v-model="activeFile" style="margin-top: 10px">
                    <van-collapse-item :title="'需用计划附件('+plan.fileList.length+')'" name="file">
                        <van-cell-group v-for="item in plan.fileList">
                            <van-cell :title="item.originalName"  @click="attrDownload(item.url)">
                                <el-icon slot="icon" class="el-icon-ali-icon-test" style="line-height: 24px;color: #1b7aff"></el-icon>
                            </van-cell>
                        </van-cell-group>
                    </van-collapse-item>
                </van-collapse>
                <van-collapse v-model="auditList" style="margin-top: 10px">
                    <van-collapse-item :title="'审核列表('+audits.length+')'" name="audit">
                        <van-cell-group v-for="item in audits" style="margin-bottom: 10px;">
                            <van-cell :title="'审批人：'+item.approveName">
                                <div slot="default">
                                    <van-tag mark type="danger" v-if="item.approveResult == 200">驳回</van-tag>
                                    <van-tag mark type="success" v-if="item.approveResult == 100">通过</van-tag>
                                </div>
                            </van-cell>
                            <van-row type="flex" justify="space-between" style="padding: 8px 16px;color: #969799;font-size: 14px;line-height: 24px">
                                <van-col span="24">审核时间:<span v-text="item.approveTime"></span></van-col>
                            </van-row>
                            <van-row type="flex" justify="space-between" style="padding: 8px 16px;color: #969799;font-size: 14px;line-height: 24px">
                                <van-col span="24">审核意见:<span v-text="item.approveOpinion"></span></van-col>
                            </van-row>
                        </van-cell-group>
                    </van-collapse-item>
                </van-collapse>
                <div style="margin-top: 10px;background-color: #ffffff;" v-if="this.canShowButton('mobile-plan-audit')">
                    <van-cell title="审批人员" :value="nextUser.approveName?nextUser.approveName:'请选择审批人员'" @click="showNext" v-if="nextApprover && nextApprover.planNextLinkVos.length > 0"/>
                    <van-popup v-model="nextShow" position="bottom">
                        <van-picker
                                v-if="nextApprover && nextApprover.planNextLinkVos.length > 0"
                                value-key="approveName"
                                :columns="nextApprover.planNextLinkVos"
                                show-toolbar
                                @cancel="closeNext"
                                @confirm="selectApprover"
                        />
                    </van-popup>
                    <van-field
                            v-model="message"
                            rows="2"
                            autosize
                            border
                            label="审批意见"
                            type="textarea"
                            maxlength="200"
                            placeholder="请输入审批意见"
                            show-word-limit
                    />
                    <van-row type="flex" justify="center" style="padding: 10px" :gutter="15">
                        <van-col span="8"><van-button type="primary" size="small" style="width: 100%" @click="auditPlan(100)">通过</van-button></van-col>
                        <van-col span="8"><van-button type="danger" size="small" style="width: 100%" @click="auditPlan(200)">不通过</van-button></van-col>
                    </van-row>
                </div>
            </div>
        </div>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                isAuth:true,
                userInfo:null,
                plan:null,
                audits:[],
                nextApprover:null,
                nextUser:{
                    approveId:0,
                    approveName:''
                },
                nextShow:false,
                activeItems:[],
                activeFile:[],
                auditList:[],
                message:'',
                id:this.$route.query.id
            }
        },
        components:{
            Loading
        },
        methods:{
            cellClassName({ row, column, rowIndex, columnIndex }){
                if((row.totalAuditQty > row.budgetAmt) && column.property == 'auditAmt'){
                    return 'colorRed';
                }
            },
            goBack(){
                this.$router.replace({name:'plan-list'})
            },
            showNext(){
                this.nextShow = true;
            },
            closeNext(){
                this.nextShow = false;
            },
            selectApprover(value){
                this.nextUser = value;
                this.nextShow = false;
            },
            checkQty(scope){
                if(!scope.row.auditAmt){
                    return;
                }
                if(!/^(([1-9]\d{0,15})|0)(\.\d{0,3})?$/.test(scope.row.auditAmt)){
                    scope.row.auditAmt = '0.000'
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: '审批数量不正确'
                    });
                    return;
                }
                let totalAuditQty = this.$math.bignumber(scope.row.totalAuditQty);
                let oldAmt = this.$math.bignumber(scope.row.oldAmt);
                let auditAmt = this.$math.bignumber(scope.row.auditAmt);
                totalAuditQty = this.$math.eval(`${totalAuditQty} - ${oldAmt} + ${auditAmt}`);
                totalAuditQty = this.$math.number(totalAuditQty);
                scope.row.oldAmt = scope.row.auditAmt;
                scope.row.totalAuditQty = totalAuditQty;
                this.plan.items.forEach((item)=>{
                    if(item.upId == scope.row.upId && item.materialId == scope.row.materialId){
                        item.totalAuditQty = totalAuditQty;
                    }
                });
            },
            checkTheoryQty(scope){
                // if(!scope.row.auditAmt){
                //     scope.row.theoryAuditAmt = ''
                //     this.$message({
                //         type: 'error',
                //         showClose: true,
                //         message: '先填写审批数量'
                //     });
                //     return;
                // }
                if(!scope.row.theoryAuditAmt){
                    return;
                }
                if(!/^(([1-9]\d{0,15})|0)(\.\d{0,3})?$/.test(scope.row.theoryAuditAmt)){
                    scope.row.theoryAuditAmt = '0.000'
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: '理论审批数量不正确'
                    });
                    return;
                }
                // if(scope.row.theoryAuditAmt > scope.row.auditAmt){
                //     scope.row.theoryAuditAmt = scope.row.auditAmt
                //     this.$message({
                //         type: 'error',
                //         showClose: true,
                //         message: '理论审批数量不能大于审批数量'
                //     });
                //     return;
                // }
            },
            async init(){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                if(this.userInfo.userType == 300){
                    await this.getPlan();
                    await this.getHistory();
                    await this.getNextApprover();
                }else{
                    this.isAuth = false;
                }
                this.isFinish = true
            },
            async getPlan(){
                this.$POST(this.$url.需用计划信息+this.id,{}).then((resp)=>{
                    if(resp.success){
                        if(resp.result.planStatus != 200){
                            this.$router.replace({name:'plan-detail',query:{id:this.id}})
                        }else{
                            resp.result.items.forEach((item)=>{
                                item.oldAmt = item.auditAmt;
                            })
                            this.plan = resp.result
                        }
                    }else{
                        if(resp.code == 410){
                            this.isAuth = false;
                        }
                    }
                })
            },
            async getHistory(){
                this.$POST(this.$url.需用计划审核历史+this.id,{}).then((resp)=>{
                    if(resp.success){
                        this.audits = resp.result
                    }
                })
            },
            async getNextApprover(){
                this.$GET(this.$url.需用计划下一个审批人+this.id,{}).then((resp)=>{
                    if(resp.success){
                        this.nextApprover = resp.result;
                        if( this.nextApprover.planNextLinkVos.length > 0){
                            this.nextUser =  this.nextApprover.planNextLinkVos[0];
                        }
                    }
                })
            },
            auditPlan(status){
                let approveId = null;
                if(status == 100){
                    if(this.nextApprover && this.nextApprover.planNextLinkVos.length > 0){
                        if(this.nextUser.approveId == 0){
                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: '没有选择审批人'
                            });
                            return;
                        }else{
                            approveId = this.nextUser.approveId;
                        }
                    }
                }
                let items = [];
                if(status == 100 || this.nextApprover.isAllowEditAuditAmt == 0){
                    let count = 0;
                    this.plan.items.forEach((item)=>{
                        items.push({id:item.id,auditAmt:item.auditAmt,theoryAuditAmt:item.theoryAuditAmt})
                        if(!item.auditAmt || item.auditAmt == 0){
                            count++;
                        }
                    })
                    if(count > 0){
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: '审批量不能为0'
                        });
                        return;
                    }
                }
                if(status == 200){
                    if(!this.message){
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: '请填写审批意见'
                        });
                        return;
                    }
                }
                this.$dialog.confirm({
                    message: status == 100 ? '确定审核通过吗?' : '确定审核不通过吗?'
                }).then(() => {
                    this.$POST(this.$url.需用计划审核,{
                        id:this.id,
                        userId:this.userInfo.id,
                        userName:this.userInfo.name,
                        auditResult:status,
                        opinion:this.message?this.message:'同意',
                        nextApproveId:approveId,
                        items:items
                    }).then((resp)=>{
                        if(resp.success){
                            this.$router.replace({name:'plan-detail',query:{id:this.id}})
                        }else{
                            this.$toast(resp.message)
                        }
                        this.message = '';
                    })
                }).catch(()=>{

                });
            },
            attrDownload(filepath){
                window.top.location.href = 'download.html?fileurl='+filepath;
            }
            
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .check-plan{background-color: #f3f3f3;min-height: 100%;overflow: hidden}
    .check-plan .line{background-color:#ffffff;height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #333333;}
    .check-plan .line-area{background-color:#ffffff;min-height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #333333;}
    .check-plan .line-1{background-color:#ffffff;height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #1b7aff;border-top: 1px solid #ebedf0;text-align: center}
    .check-plan .right{text-align: right;color: #777777;}
    .check-plan .auto-class{color: #777777;}
    .check-plan .money{color: #FF4444;font-weight: 700;}
</style>