<template>
    <div class="add-stock-delivery padding-top-56" v-if="isFinish">
        <div v-if="!isAuth">
            抱歉，您没有权限访问该页面！
        </div>
        <div v-else>
            <div v-if="userInfo&&userInfo.userType == 100">
                <van-nav-bar title="送货单" left-text="返回" @click-left="goBack" fixed>
                </van-nav-bar>
                <div v-if="stockDelivery">
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">送货单号:</van-col>
                        <van-col span="14" v-text="stockDelivery.stockDeliveryCode"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">项目名称:</van-col>
                        <van-col span="14" v-text="stockDelivery.projectName"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">下单日期:</van-col>
                        <van-col span="14" v-text="stockDelivery.orderDate"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">拟送货日期:</van-col>
                        <van-col span="14" v-text="stockDelivery.deliveryDate"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">实际送货日期:</van-col>
                        <van-col span="14" v-text="stockDelivery.realDeliveryDate"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">状态:</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 100">新建</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 200">开始送货</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 300">材料员已验收</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 400">分包队伍已验收</van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">送货金额:</van-col>
                        <van-col span="14" v-text="totalAmt+'元'" class="money"></van-col>
                    </van-row>
                    <el-form :model="stockDelivery" ref="itemsForm">
                        <el-table
                                :data="stockDelivery.items"
                                border
                                row-class-name="default-row"
                                style="width: 100%;margin-top: 10px;">
                            <el-table-column
                                    fixed
                                    prop="materialName"
                                    label="名称"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="spsNo"
                                    label="规格"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="materialUnit"
                                    label="单位"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="brand"
                                    label="品牌"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="planDeliveryQty"
                                    label="计划数量"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="notUpDeliveryQty"
                                    label="未送数量"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="currentQty"
                                    label="本次送货数量"
                                    width="150"
                                    label-class-name="editColumn">
                            </el-table-column>
                            <el-table-column
                                    prop="currentAmt"
                                    label="送货单价"
                                    width="150"
                                    label-class-name="editColumn">
                            </el-table-column>
                            <el-table-column
                                    prop="upName"
                                    label="使用部位"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="remark"
                                    label="备注"
                                    width="150">
                            </el-table-column>
                        </el-table>
                    </el-form>
                </div>
            </div>
            <div v-if="userInfo&&userInfo.userType == 200">
                <van-nav-bar title="送货单" left-text="返回" @click-left="goBack" fixed></van-nav-bar>
                <div v-if="stockDelivery">
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">送货单号:</van-col>
                        <van-col span="14" v-text="stockDelivery.stockDeliveryCode"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">项目名称:</van-col>
                        <van-col span="14" v-text="stockDelivery.projectName"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">下单日期:</van-col>
                        <van-col span="14" v-text="stockDelivery.orderDate"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">拟送货日期:</van-col>
                        <van-col span="14" v-text="stockDelivery.deliveryDate"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">实际送货日期:</van-col>
                        <van-col span="14" v-text="stockDelivery.realDeliveryDate"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">状态:</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 100">新建</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 200">开始送货</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 300">材料员已验收</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 400">分包队伍已验收</van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">送货金额:</van-col>
                        <van-col span="14" v-text="totalAmt+'元'" class="money"></van-col>
                    </van-row>
                    <el-form :model="stockDelivery" ref="itemsForm">
                        <el-table
                                :data="stockDelivery.items"
                                border
                                row-class-name="default-row"
                                style="width: 100%;margin-top: 10px;">
                            <el-table-column
                                    fixed
                                    prop="materialName"
                                    label="名称"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="spsNo"
                                    label="规格"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="materialUnit"
                                    label="单位"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="brand"
                                    label="品牌"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="planDeliveryQty"
                                    label="计划数量"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="notUpDeliveryQty"
                                    label="未送数量"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="currentQty"
                                    label="本次送货数量"
                                    width="150"
                                    label-class-name="editColumn">
                            </el-table-column>
                            <el-table-column
                                    prop="currentAmt"
                                    label="送货单价"
                                    width="150"
                                    label-class-name="editColumn">
                            </el-table-column>
                            <el-table-column
                                    prop="upName"
                                    label="使用部位"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="remark"
                                    label="备注"
                                    width="150">
                            </el-table-column>
                        </el-table>
                    </el-form>
                    <van-row gutter="10" style="background-color: #ffffff;margin-top: 10px;padding-top: 10px;font-size: 12px;color: #333333;">
                        <van-col span="5" class="right">照片:</van-col>
                        <van-col span="19">
                            <van-uploader
                                    :max-count="stockDelivery.fileList.length"
                                    v-model="stockDelivery.fileList"
                                    :deletable="false"
                            />
                        </van-col>
                    </van-row>
                    <van-row v-if="stockDelivery && stockDelivery.deliveryStatus == 300">
                        <van-col span="20" offset="2">
                            <van-button type="info" @click="receive" :disabled="receiveDisabled" size="small" style="width: 100%">验收</van-button>
                        </van-col>
                    </van-row>
                </div>
            </div>
            <div v-if="userInfo&&userInfo.userType == 300">
                <van-nav-bar title="送货单" left-text="返回" @click-left="goBack" fixed>
                    <template #right>
                        <van-button type="info" size="small" v-if="stockDelivery && stockDelivery.deliveryStatus == 200" @click="receive" :disabled="receiveDisabled" style="margin-right: 5px">验收</van-button>
                        <van-button type="primary" size="small" v-if="stockDelivery && stockDelivery.deliveryStatus == 200" @click="save">保存</van-button>
                    </template>
                </van-nav-bar>
                <div v-if="stockDelivery">
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">送货单号:</van-col>
                        <van-col span="14" v-text="stockDelivery.stockDeliveryCode"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">项目名称:</van-col>
                        <van-col span="14" v-text="stockDelivery.projectName"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">下单日期:</van-col>
                        <van-col span="14" v-text="stockDelivery.orderDate"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">拟送货日期:</van-col>
                        <van-col span="14" v-text="stockDelivery.deliveryDate"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">实际送货日期:</van-col>
                        <van-col span="14" v-text="stockDelivery.realDeliveryDate"></van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">状态:</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 100">新建</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 200">开始送货</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 300">材料员已验收</van-col>
                        <van-col span="14" v-if="stockDelivery.deliveryStatus == 400">分包队伍已验收</van-col>
                    </van-row>
                    <van-row gutter="10" class="line">
                        <van-col span="6" offset="2" class="right">送货金额:</van-col>
                        <van-col span="14" v-text="totalAmt+'元'" class="money"></van-col>
                    </van-row>
                    <div v-if="stockDelivery.deliveryStatus == 200">
                        <el-form :model="stockDelivery" ref="itemsForm">
                            <el-table
                                    :data="stockDelivery.items"
                                    border
                                    row-class-name="default-row"
                                    style="width: 100%;margin-top: 10px;">
                                <el-table-column
                                        fixed
                                        prop="materialName"
                                        label="名称"
                                        width="150"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="spsNo"
                                        label="规格"
                                        width="150"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="materialUnit"
                                        label="单位"
                                        width="150"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="brand"
                                        label="品牌"
                                        width="100"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="planDeliveryQty"
                                        label="计划数量"
                                        width="120" align="right"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                    prop="upDeliveryQty"
                                    label="已送数量(含本次)"
                                    width="160" align="right"
                                    sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="notUpDeliveryQty"
                                        label="未送数量"
                                        width="120" align="right"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        label="本次送货数量"
                                        width="150"
                                        label-class-name="editColumn">
                                    <template slot-scope="scope">
                                        <van-field type="number" v-model="scope.row.currentQty" placeholder="请输入本次送货数量" @blur="checkQty(scope)"/>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        label="送货单价"
                                        width="150"
                                        label-class-name="editColumn">
                                    <template slot-scope="scope">
                                        <van-field type="number" v-model="scope.row.currentAmt" placeholder="请输入送货单价"  @blur="checkAmt(scope)"/>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="upName"
                                        label="使用部位"
                                        width="150"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="remark"
                                        label="备注"
                                        width="150"
                                        sortable>
                                </el-table-column>
                            </el-table>
                        </el-form>
                        <van-row gutter="10" style="background-color: #ffffff;margin-top: 10px;padding-top: 10px;font-size: 12px;color: #333333;">
                            <van-col span="5" class="right">照片:</van-col>
                            <van-col span="19">
                                <van-uploader
                                        :max-count="50"
                                        v-model="stockDelivery.fileList"
                                        capture="camera"
                                        :after-read="uploadImage"
                                        @delete="deleteImage"
                                />
                            </van-col>
                        </van-row>
                    </div>
                    <div v-else>
                        <el-form :model="stockDelivery" ref="itemsForm">
                            <el-table
                                    :data="stockDelivery.items"
                                    border
                                    row-class-name="default-row"
                                    style="width: 100%;margin-top: 10px;">
                                <el-table-column
                                        fixed
                                        prop="materialName"
                                        label="名称"
                                        width="150"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="spsNo"
                                        label="规格"
                                        width="150"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="materialUnit"
                                        label="单位"
                                        width="150"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="brand"
                                        label="品牌"
                                        width="100"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="planDeliveryQty"
                                        label="计划数量"
                                        width="120" align="right"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                    prop="upDeliveryQty"
                                    label="已送数量(含本次)"
                                    width="160" align="right"
                                    sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="notUpDeliveryQty"
                                        label="未送数量"
                                        width="120" align="right"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="currentQty"
                                        label="本次送货数量"
                                        width="120" align="right"
                                        sortable
                                        label-class-name="editColumn">
                                </el-table-column>
                                <el-table-column
                                        prop="currentAmt"
                                        label="送货单价"
                                        width="120" align="right"
                                        sortable
                                        label-class-name="editColumn">
                                </el-table-column>
                                <el-table-column
                                        prop="upName"
                                        label="使用部位"
                                        width="150"
                                        sortable>
                                </el-table-column>
                                <el-table-column
                                        prop="remark"
                                        label="备注"
                                        width="150"
                                        sortable>
                                </el-table-column>
                            </el-table>
                        </el-form>
                        <!-- <van-row gutter="10" style="background-color: #ffffff;margin-top: 10px;padding-top: 10px;font-size: 12px;color: #333333;">
                            <van-col span="5" class="right">照片:</van-col>
                            <van-col span="19">
                                <van-uploader
                                        :max-count="50"
                                        v-model="stockDelivery.fileList"
                                        capture="camera"
                                        :after-read="uploadImage"
                                        @delete="deleteImage"
                                />
                            </van-col>
                        </van-row> -->
                        <van-row gutter="10" style="background-color: #ffffff;margin-top: 10px;padding-top: 10px;font-size: 12px;color: #333333;">
                            <van-col span="5" class="right">照片:</van-col>
                            <van-col span="19">
                                <van-uploader
                                        :max-count="stockDelivery.fileList.length"
                                        v-model="stockDelivery.fileList"
                                        :deletable="false"
                                />
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import Exif from 'exif-js'
    const wx = require('weixin-js-sdk')
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                receiveDisabled: false,
                isAuth:true,
                userInfo:null,
                stockDelivery:null,
                totalAmt:'',
                id:this.$route.query.id,
                address:'',
                ak:'5wquD33EBDeNGP0lfGV9Cr6ztCAexm8O'
            }
        },
        components:{
            Loading
        },
        methods:{
            goBack(){
                this.$router.replace({name:'bill-detail',query:{id:this.stockDelivery.stockOrderId}})
            },
            checkQty(scope){
                if(!scope.row.currentQty){
                    return;
                }
                // realDeliveryQty -- 当前送货单的送货量
                let realDeliveryQty = this.$math.bignumber(scope.row.realDeliveryQty);

                if(!/^(([1-9]\d{0,15})|0)(\.\d{0,3})?$/.test(scope.row.currentQty)){
                    scope.row.currentQty = realDeliveryQty+""
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: '送货数量格式不正确'
                    });
                    return;
                }

                // realDeliveryQty -- 当前送货单的送货量
                // 最大可调整 本条明细 的实际送货数量的110%
                // 2021-12-14 改成120%
               let currentMaxQty = this.$math.number(this.$math.eval(`${realDeliveryQty} * 1.2`));
                let currentQty = this.$math.number(scope.row.currentQty)
                if(currentQty > currentMaxQty){
                    scope.row.currentQty = realDeliveryQty+""
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: '本次送货数量不能超过实际货数量（' + realDeliveryQty + '）的20%'
                    });
                    return;
                }
                this.calcAmt();
            },
            checkAmt(scope){
                if(!scope.row.currentAmt){
                    return;
                }
                if(!/^(([1-9]\d{0,15})|0)(\.\d{0,2})?$/.test(scope.row.currentAmt)){
                    scope.row.currentQty = '0.000'
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: '送货单价不正确'
                    });
                    return;
                }
                this.calcAmt();
            },
            calcAmt(){
                this.totalAmt = 0;
                let totalAmt = this.$math.bignumber(this.totalAmt)
                this.stockDelivery.items.forEach((item)=>{
                    let currentQty = this.$math.number(item.currentQty)
                    let currentAmt = this.$math.number(item.currentAmt)
                    totalAmt = this.$math.add(totalAmt,this.$math.eval(`${currentQty} * ${currentAmt}`))
                })
                this.totalAmt = this.$math.number(totalAmt).toFixed(2)
            },
            async init(){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                await this.getBillDetail();
                if(this.userInfo.userType == 300){
                    await this.initWx();
                }
                this.isFinish = true
                this.receiveDisabled = false;
            },
            async initWx(){
                this.$POST(this.$url.获取jsapi签名,{
                    url:window.location.href
                }).then((resp)=>{
                    if(resp.success){
                        let _this = this;
                        wx.config({
                            debug : false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId : resp.result.appId, // 必填，公众号的唯一标识
                            timestamp : resp.result.timestamp, // 必填，生成签名的时间戳
                            nonceStr : resp.result.nonceStr, // 必填，生成签名的随机串
                            signature : resp.result.signature,// 必填，签名，见附录1
                            jsApiList : ['getLocation'] //获取地理位置接口
                            // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function() {
                            wx.getLocation({
                                success : (res) => {
                                    _this.translate(res.longitude, res.latitude);
                                },
                                cancel : (res) => {
                                    _this.$toast('用户拒绝授权获取地理位置');
                                }
                            });
                        });
                        wx.error((res) => {
                            _this.$toast("调用微信jsapi返回的状态:" + res.errMsg);
                        });
                    }
                })
            },
            translate(x,y){
                let p = x + "," + y;
                let url = "http://api.map.baidu.com/geoconv/v1/?coords=" + p + "&from=1&to=5&ak=" + this.ak;
                this.$JSONP(url).then((resp)=>{
                    if(resp.status == 0){
                        this.getAddress(resp.result[0])
                    }
                })
            },
            getAddress(point){
                let p = point.y + "," + point.x;
                let url = "http://api.map.baidu.com/geocoder/v2/?callback=renderReverse&location=" + p +"&output=json&ak=" + this.ak;
                this.$JSONP(url).then((resp)=>{
                    if(resp.status == 0){
                        let road = resp.result.formatted_address + resp.result.sematic_description;
                        this.address = road;
                        console.log(this.address)
                    }else{
                        this.$toast("无法转换地址！");
                    }
                })
            },
            async getBillDetail(){
                this.$POST(this.$url.验收送货单详情,{
                    id:this.id,
                    userId:this.userInfo.id,
                    userType:this.userInfo.userType
                }).then((resp)=>{
                    if(resp.success){
                        let items = [];
                        resp.result.items.forEach((item)=>{
                            item.planDeliveryQty = item.planDeliveryQty.toFixed(3)
                            item.notUpDeliveryQty = item.notUpDeliveryQty.toFixed(3)
                            item.currentQty = item.realDeliveryQty.toFixed(3);
                            item.currentAmt = item.price.toFixed(2);
                            items.push(item);
                        })
                        resp.result.fileList.forEach((item)=>{
                            item.isImage = true;
                        })
                        resp.result.items = items;
                        this.stockDelivery = resp.result;
                        this.totalAmt = this.stockDelivery.deliveryAmt.toFixed(2);
                        this.calcAmt();
                        this.isAuth = true;
                    }else{
                        if(resp.code == 410){
                            this.isAuth = false;
                        }
                    }
                })
            },
            save(){
                let items = [];
                this.stockDelivery.items.forEach((item)=>{
                    if('0.000' != item.currentQty){
                        items.push({id:item.id,stockOrderItemId:item.stockOrderItemId,currentQty:item.currentQty,currentAmt:item.currentAmt,brand:item.brand});
                    }
                })
                this.$POST(this.$url.编辑送货单,{
                    userId:this.userInfo.id,
                    id:this.id,
                    date:this.stockDelivery.realDeliveryDate,
                    totalAmt:this.totalAmt,
                    items:items
                }).then((resp)=>{
                    if(resp.success){
                        this.$toast('保存成功');
                        this.init();
                    }else{
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: resp.message
                        });
                        return;
                    }
                })
            },
            receive(){
                this.receiveDisabled = true;
                if(this.userInfo.userType == 300){
                    if(!this.stockDelivery.fileList || this.stockDelivery.fileList.length < 2){
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: '至少要上传两张照片'
                        });
                        this.receiveDisabled = false;
                        return;
                    }
                }
                let items = [];
                this.stockDelivery.items.forEach((item)=>{
                    if('0.000' != item.currentQty){
                        items.push({id:item.id,stockOrderItemId:item.stockOrderItemId,currentQty:item.currentQty,currentAmt:item.currentAmt,brand:item.brand});
                    }
                })
                this.$dialog.confirm({
                    message: '确定要执行验收吗?'
                }).then(() => {
                    this.$POST(this.$url.验收送货单,{
                        id:this.id,
                        userId:this.userInfo.id,
                        userType:this.userInfo.userType,
                        totalAmt:this.totalAmt,
                        items:items
                    }).then((resp)=>{
                        if(resp.success){
                            this.$toast('验收成功');
                            this.$router.replace({name:'stock-delivery-detail',query:{id:this.id,billId:this.stockDelivery.stockOrderId}})
                        }else{
                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: resp.message
                            });
                            this.receiveDisabled = false;
                        }
                    })
                }).catch(()=>{
                    // 点击了取消
                    this.receiveDisabled = false;
                });
            },
            postImage(file,base64,detail,name,type){

                file.file = this.dataURLtoFile(base64,name,type)
                file.status = 'uploading';
                file.message = '上传中...';
                let fd = new FormData()
                fd.append('file', file.file)
                fd.append('businessId', this.id)
                fd.append('businessType', 'StockDelivery')
                fd.append('projectName', this.stockDelivery.projectName)
                fd.append('userName', this.userInfo.name)
                fd.append('address', this.address)
                this.$UPLOAD(this.$url.上传水印文件, fd, {headers: {
                        'Content-Type': 'multipart/form-data'
                }}).then(res => {
                    file.status = 'done';
                    file.message = '';
                    this.stockDelivery.fileList[detail.index].content = res.result.url;
                    this.stockDelivery.fileList[detail.index].id = res.result.id;
                }).catch(err => {
                    file.status = 'failed';
                    file.message = '上传失败';
                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: err
                    });
                })
            },
            // 组件方法 获取 流
            uploadImage(file,detail) {
                let name = file.file.name // 获取文件名
                let type = file.file.type // 获取类型
                this.imgPreview(file.file,detail,name,type)
            },
            // 处理图片
            imgPreview(file,detail,name,type) {
                let self = this
                let Orientation
                //去获取拍照时的信息，解决拍出来的照片旋转问题   npm install exif-js --save   这里需要安装一下包
                Exif.getData(file, function() {
                    Orientation = Exif.getTag(this, 'Orientation')
                })
                // 看支持不支持FileReader
                if (!file || !window.FileReader) return
                if (/^image/.test(file.type)) {
                    // 创建一个reader
                    let reader = new FileReader()
                    // 将图片2将转成 base64 格式
                    reader.readAsDataURL(file)
                    // 读取成功后的回调
                    reader.onloadend = function() {
                        let result = this.result
                        let img = new Image()
                        img.src = result
                        //判断图片是否大于500K,是就直接上传，反之压缩图片
                        if (this.result.length <= 500 * 1024) {
                            console.log("小于500k")
                            // 上传图片
                            self.postImage(file,this.result,detail,name,type);
                        } else {
                            console.log("大于500k")
                            img.onload = function() {
                                let data = self.compress(img, Orientation)
                                // 上传图片
                                self.postImage(file,data,detail,name,type);
                            }
                        }
                    }
                }
            },
            // 压缩图片
            compress(img, Orientation) {
                let canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                //瓦片canvas
                let tCanvas = document.createElement('canvas')
                let tctx = tCanvas.getContext('2d')
                // let initSize = img.src.length;
                let width = img.width
                let height = img.height
                //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
                let ratio
                if ((ratio = (width * height) / 4000000) > 1) {
                    // console.log("大于400万像素");
                    ratio = Math.sqrt(ratio)
                    width /= ratio
                    height /= ratio
                } else {
                    ratio = 1
                }
                canvas.width = width
                canvas.height = height
                //    铺底色
                ctx.fillStyle = '#fff'
                ctx.fillRect(0, 0, canvas.width, canvas.height)
                //如果图片像素大于100万则使用瓦片绘制
                let count
                if ((count = (width * height) / 1000000) > 1) {
                    // console.log("超过100W像素");
                    count = ~~(Math.sqrt(count) + 1) //计算要分成多少块瓦片
                    //      计算每块瓦片的宽和高
                    let nw = ~~(width / count)
                    let nh = ~~(height / count)
                    tCanvas.width = nw
                    tCanvas.height = nh
                    for (let i = 0; i < count; i++) {
                        for (let j = 0; j < count; j++) {
                            tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
                            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
                        }
                    }
                } else {
                    ctx.drawImage(img, 0, 0, width, height)
                }
                //修复ios上传图片的时候 被旋转的问题
                if (Orientation != '' && Orientation != 1) {
                    switch (Orientation) {
                        case 6: //需要顺时针（向左）90度旋转
                            this.rotateImg(img, 'left', canvas)
                            break
                        case 8: //需要逆时针（向右）90度旋转
                            this.rotateImg(img, 'right', canvas)
                            break
                        case 3: //需要180度旋转
                            this.rotateImg(img, 'right', canvas) //转两次
                            this.rotateImg(img, 'right', canvas)
                            break
                    }
                }
                //进行最小压缩
                let ndata = canvas.toDataURL('image/jpeg', 0.1)
                tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
                console.log(ndata)
                return ndata
            },
            // 旋转图片
            rotateImg(img, direction, canvas) {
                //最小与最大旋转方向，图片旋转4次后回到原方向
                const min_step = 0
                const max_step = 3
                if (img == null) return
                //img的高度和宽度不能在img元素隐藏后获取，否则会出错
                let height = img.height
                let width = img.width
                let step = 2
                if (step == null) {
                    step = min_step
                }
                if (direction == 'right') {
                    step++
                    //旋转到原位置，即超过最大值
                    step > max_step && (step = min_step)
                } else {
                    step--
                    step < min_step && (step = max_step)
                }
                //旋转角度以弧度值为参数
                let degree = (step * 90 * Math.PI) / 180
                let ctx = canvas.getContext('2d')
                switch (step) {
                    case 0:
                        canvas.width = width
                        canvas.height = height
                        ctx.drawImage(img, 0, 0)
                        break
                    case 1:
                        canvas.width = height
                        canvas.height = width
                        ctx.rotate(degree)
                        ctx.drawImage(img, 0, -height)
                        break
                    case 2:
                        canvas.width = width
                        canvas.height = height
                        ctx.rotate(degree)
                        ctx.drawImage(img, -width, -height)
                        break
                    case 3:
                        canvas.width = height
                        canvas.height = width
                        ctx.rotate(degree)
                        ctx.drawImage(img, -width, 0)
                        break
                }
            },
            //将base64转换为文件
            dataURLtoFile(dataurl,name,type) {
                var arr = dataurl.split(','),
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n)
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n)
                }
                return new File([u8arr], name, {
                    type: type
                })
            },
            deleteImage(file){
                this.$DELETE(this.$url.删除文件+file.id,null).then((resp)=>{
                    if(resp.success){

                    }
                })
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .add-stock-delivery{background-color: #f3f3f3;min-height: 100%;overflow: hidden}
    .add-stock-delivery .line{background-color:#ffffff;height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #333333;}
    .add-stock-delivery .right{text-align: right;color: #777777;}
    .add-stock-delivery .auto-class{color: #777777;}
    .add-stock-delivery .money{color: #FF4444;font-weight: 700;}
</style>