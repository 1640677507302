<template>
    <div class="check-payment padding-top-56" v-if="isFinish">
        <van-nav-bar title="资金支付详情" left-text="返回" @click-left="goBack" fixed></van-nav-bar>
        <div v-if="payment">
            <van-row gutter="10" class="line" v-for="item in payment.detailField">
                <van-col span="7" offset="2" class="right"><span v-text="item[0]"></span>:</van-col>
                <van-col span="13"  v-text="item[1]"></van-col>
            </van-row>
        </div>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                userInfo:null,
                payment:null,
                id:this.$route.query.id
            }
        },
        components:{
            Loading
        },
        methods:{
            goBack(){
                this.$router.replace({name:'check-payment',query:{id:this.id}})
            },
            async init(){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                await this.getPayment();
                this.isFinish = true
            },
            async getPayment(){
                this.$POST(this.$url.资金支付信息+this.id,{}).then((resp)=>{
                    if(resp.success){
                        this.payment = resp.result
                    }else{
                        if(resp.code == 410){
                            this.isAuth = false;
                        }
                    }
                })
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .check-payment{background-color: #f3f3f3;min-height: 100%;overflow: hidden}
    .check-payment .line{background-color:#ffffff;height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #333333;}
    .check-payment .line-1{background-color:#ffffff;height: 35px;line-height: 35px;text-align: left;font-size: 12px!important;color: #1b7aff;border-top: 1px solid #ebedf0;text-align: center}
    .check-payment .right{text-align: right;color: #777777;}
    .check-payment .auto-class{color: #777777;}
    .check-payment .money{color: #FF4444;font-weight: 700;}
</style>