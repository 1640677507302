<template>
    <div class="login" v-if="isFinish">
        <img src="../assets/img/login.png" class="img">
        <el-row>
            <el-col :xs="20" :sm="20" :offset="2">
                <el-form :model="loginForm" :rules="rules"  ref="loginForm" label-position="left">
                    <el-form-item prop="account">
                        <el-input v-model="loginForm.account" placeholder="登录账号" size="small" prefix-icon="el-icon-ali-bussiness-man"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="loginForm.password" type="password" placeholder="登录密码" size="small" prefix-icon="el-icon-ali-password"></el-input>
                    </el-form-item>
                    <!-- <el-form-item  prop="captcha">
                        <el-row :gutter="10">
                            <el-col :xs="14" :sm="14">
                                <el-input v-model="loginForm.captcha" maxlength="4" placeholder="验证码" size="small" prefix-icon="el-icon-ali-pic"></el-input>
                            </el-col>
                            <el-col :xs="10" :sm="10"  style="height:40px;">
                                <img :src="captchaImg" @click="getCaptchaImg" style="width: 120px; height: 36px;">
                            </el-col>
                        </el-row> -->
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" style="width: 100%;" @click="login">登录并绑定</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-row :gutter="10">
                            <el-col :xs="12" :sm="12">
                                <el-button @click="goRegister(100)" size="small" style="width: 100%;">供应商注册</el-button>
                            </el-col>
                            <el-col :xs="12" :sm="12">
                                <el-button @click="goRegister(200)" size="small" style="width: 100%;">分包商注册</el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-form>
                <div class="target-href">
                    <a href="https://beian.miit.gov.cn" target="_blank">浩创置业集团服务平台&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;豫ICP备15011627号-2</a>
                    <p>技术支持：咨宸科技&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;电话：18637131189</p>
                </div>
            </el-col>
        </el-row>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                captchaImg: "",
                loginForm:{
                    account:'',
                    password:'',
                    captcha:'',
                    openId:''
                },
                rules:{
                    account: [
                        { required: true, message: '请输入登录账号', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入登录密码', trigger: 'blur' }
                    ]
                    // captcha: [
                    //     { required: true, message: '请输入验证码', trigger: 'blur' }
                    // ]
                }
            }
        },
        components:{
            Loading
        },
        methods:{
            init(){
                let openId = localStorage.getItem('openId')
                this.$GET(this.$url.登录检查+openId,null).then((resp)=>{
                    if(resp.success){
                        localStorage.setItem("userInfo",JSON.stringify(resp.result))
                        localStorage.setItem("token",openId)
                        this.$router.replace({name:'index'})
                    }else{
                        this.loginForm.openId = localStorage.getItem('openId')
                        this.getCaptchaImg();
                        this.isFinish = true;
                    }
                })
            },
            getOpenId(){
                this.$GET(this.$url.获取openId+this.$route.query.code,null).then((resp)=>{
                    if(resp.success){
                        localStorage.setItem('openId',resp.result)
                        this.init();
                    }
                })
            },
            getCaptchaImg() {
                // let openId = localStorage.getItem('openId')

                let openId = "";
                if(this.isWeiXin()){
                    openId = localStorage.getItem('openId')
                }else{
                    console.log("不是微信，openId::", this.loginForm.openId)
                    openId = this.loginForm.openId
                }

                this.captchaImg = '/mm/wx/captcha/draw/'+openId+'?time='+new Date().getTime()
            },
            goRegister(userType){
                this.$router.replace({name:'register',query:{userType:userType}})
            },
            login(){
                this.$refs.loginForm.validate((valid) => {
                    if (valid) {
                        this.$POST(this.$url.登录,this.loginForm).then((resp)=>{
                            if(resp.success){
                                localStorage.setItem("userInfo",JSON.stringify(resp.result))
                                localStorage.setItem("token",this.loginForm.openId)
                                this.$router.replace({name:'index'})
                            }else{
                                localStorage.removeItem("userInfo");
                                localStorage.removeItem("token");
                                this.$dialog.alert({
                                    message: resp.message
                                }).then(() => {
                                    this.loginForm.captcha = '';
                                    this.getCaptchaImg();
                                });
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            //判断是否是微信浏览器的函数
            isWeiXin(){
            //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
                var ua = window.navigator.userAgent.toLowerCase();
                //通过正则表达式匹配ua中是否含有MicroMessenger字符串
                if(ua.match(/MicroMessenger/i) == 'micromessenger'){
                    return true;
                } else{
                    return false;
                }
            }
        },
        mounted() {
            if(this.isWeiXin()){
                let openId = localStorage.getItem('openId')
                if(openId){
                    this.init();
                }else{
                    this.getOpenId();
                }
            }else{
                this.loginForm.openId = new Date().getTime(); 
                this.getCaptchaImg();
                this.isFinish = true;
            }

            
        }
    }
</script>

<style scoped>
    .login{}
    .login .img{width: 100%;}

    .target-href{
        width: 100%;
        text-align: center;
        margin-bottom: 2px;
        font-size: 10px;
    }
    .target-href a{
        color: #409EFF;
    }
    .target-href p{
        color: #606266;
        margin-top: 4px;
    }

</style>