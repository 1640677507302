import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Eachats from 'echarts';

Vue.config.productionTip = false

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// import Vconsole from 'vconsole';
// const vConsole = new Vconsole()
// Vue.use(vConsole)

import { create, all } from 'mathjs'
const config = {
  number:'BigNumber',
  precision:30
}
const math = create(all, config)
Vue.prototype.$math = math;

//引入css重置文件,基本的样式文件
require('./assets/css/reset.css')
require('./assets/css/iconfont.css')

import '@/assets/js/http/url'
import '@/assets/js/http/http'

Vue.prototype.$echarts = Eachats;

Vue.prototype.canShowButton = (key) => {
  let result = false;
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  userInfo.permissions.forEach((item) => {
    if (item.buttonType == key) {
      result = true;
    }
  })
  return result;
}

router.beforeEach((to, from, next) => {
  if(to.meta.needLogin){
    let token = localStorage.getItem('token')
    if(token){
      if (to.meta.title) {
        document.title = to.meta.title
      }
      next()
    }else{
      next({name:'login'})
    }
  }else{
    if (to.meta.title) {
      document.title = to.meta.title
    }
    if(to.name == 'login'){
      // 判断是否是微信浏览器
      if(navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1){
        if(to.query.code){
          next()
        }else{
          document.title = '';
          let url = window.location.protocol+'//'+window.location.host+'/login'
          window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx18b7c00f9bc01ba6&redirect_uri="+encodeURIComponent(url)+"&response_type=code&scope=snsapi_base&state=material#wechat_redirect";
        }
      }else{
        next()
      }
      
    }else{
      next()
    }
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
