import Vue from 'vue'
import axios from 'axios'
import { Message } from 'element-ui'

// 默认配置
axios.defaults.timeout = 60000;
axios.defaults.responseType = 'json';
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';

// 请求拦截
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  let token = localStorage.getItem('token')
  if(token){
    config.headers['accessToken'] = token;
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 相应拦截
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

Vue.prototype.$UPLOAD = function (url, data, config) {
  let _this =this;
  let promise = new Promise((resolve, reject) => {
    axios.post(url, data, config)
        .then((response) => {
          resolve(response.data)
        }).catch((error) => {
      Message.error('发生错误...');
    });
  });
  return promise;
};

Vue.prototype.$POST = function (url, data) {
  let _this =this;
  let promise = new Promise((resolve, reject) => {
    axios.post(url, data)
    .then((response) => {
      resolve(response.data)
    }).catch((error) => {
      Message.error('发生错误...');
    });
  });
  return promise;
};

Vue.prototype.$GET = function (url, data) {
  let _this =this;
  let promise = new Promise((resolve, reject) => {
    axios.get(url, data)
    .then((response) => {
      resolve(response.data)
    }).catch((error) => {
      Message.error('发生错误...');
    });
  });
  return promise;
};

Vue.prototype.$DELETE = function (url, data) {
  let _this =this;
  let promise = new Promise((resolve, reject) => {
    axios.delete(url, data)
    .then((response) => {
      resolve(response.data)
    }).catch((error) => {
      Message.error('发生错误...');
    });
  });
  return promise;
};

Vue.prototype.$JSONP = function (url) {
  let _this =this;
  let promise = new Promise((resolve, reject) => {
    axios.jsonp(url)
    .then((response) => {
      resolve(response)
    }).catch((error) => {
      Message.error('发生错误...');
    });
  });
  return promise;
};

axios.jsonp = (url) => {
  return new Promise((resolve, reject) => {
    window.jsonCallBack = (result) => {
      resolve(result)
    }
    let JSONP = document.createElement("script");
    JSONP.type = "text/javascript";
    JSONP.src = `${url}&callback=jsonCallBack`;
    document.getElementsByTagName("head")[0].appendChild(JSONP);
    setTimeout(() => {
      document.getElementsByTagName("head")[0].removeChild(JSONP)
    }, 500)
  })
}

