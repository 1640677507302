<template>
    <div class="index padding-top-56" v-if="isFinish">
        <van-nav-bar title="材料管理" fixed></van-nav-bar>
        <van-grid :column-num="3">
            <van-grid-item is-link  @click="billList">
                <img src="@/assets/img/icon1.png" alt="" class="van-icon van-grid-item__icon">
                <span class="van-grid-item__text">我的单据</span>
            </van-grid-item>
            <!-- <van-grid-item is-link  v-if="userInfo.userType == 300 && this.canShowButton('mobile-payment-view')"  @click="paymentList">
                <img src="@/assets/img/icon2.png" alt="" class="van-icon van-grid-item__icon">
                <span class="van-grid-item__text">资金审批单据</span>
            </van-grid-item>
            <van-grid-item is-link  v-if="userInfo.userType == 100" @click="paymentList">
                  <img src="@/assets/img/icon2.png" alt="" class="van-icon van-grid-item__icon">
                  <span class="van-grid-item__text">资金审批单据</span>
            </van-grid-item> -->
            <van-grid-item is-link  v-if="userInfo.userType == 300 && this.canShowButton('mobile-plan-view')" @click="planList">
                  <img src="@/assets/img/icon3.png" alt="" class="van-icon van-grid-item__icon">
                  <span class="van-grid-item__text">需用计划审批单据</span>
            </van-grid-item>
            <van-grid-item is-link  v-if="userInfo.userType != 300" @click="editPassword">
                <img src="@/assets/img/icon6.png" alt="" class="van-icon van-grid-item__icon">
                <span class="van-grid-item__text">修改密码</span>
            </van-grid-item>
            <van-grid-item is-link  @click="exit">
                <img src="@/assets/img/icon7.png" alt="" class="van-icon van-grid-item__icon">
                <span class="van-grid-item__text">退出</span>
            </van-grid-item>
        </van-grid>
    </div>
    <loading v-else="!isFinish"></loading>
</template>

<script>
    import Loading from '@/components/loading'
    export default {
        data(){
            return {
                isFinish:false,
                userInfo:null
            }
        },
        components:{
            Loading
        },
        methods:{
            init(){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.isFinish = true;
            },
            billList(){
                this.$router.replace({name:'bill-list'})
            },
            paymentList(){
                this.$router.replace({name:'payment-list'})
            },
            planList(){
                this.$router.replace({name:'plan-list'})
            },
            editPassword(){
                this.$router.replace({name:'password'})
            },
            exit(){
                let openId = localStorage.getItem('openId')
                this.$GET(this.$url.退出登录+openId,null).then((resp)=>{
                    if(resp.success){
                        localStorage.removeItem("userInfo")
                        localStorage.removeItem("token")
                        this.$router.replace({name:'login'})
                    }
                })
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    .index{}
    .index .van-cell{
        font-size: 15px!important;
    }
</style>