import Vue from 'vue'

const url = {
  '上传文件':'/mm/upload/attachment',
  '上传水印文件':'/mm/upload/attachment/text',
  '删除文件':'/mm/attachment/delete/',
  '注册':'/mm/wx/user/register',
  '登录':'/mm/wx/user/login',
  '获取openId':'/mm/wx/get/openid/',
  '获取jsapi签名':'/mm/wx/get/jsapi/signature',
  '登录检查':'/mm/wx/user/login/check/',
  '修改密码':'/mm/wx/user/edit/password',
  '退出登录':'/mm/wx/user/logout/',
  '项目列表':'/mm/wx/bill/project/list',
  '送货通知单列表':'/mm/wx/bill/stock/order/list',
  '送货通知单详情':'/mm/wx/bill/stock/order/detail/',
  '送货单集合':'/mm/wx/bill/stock/delivery/list/',
  '送货单详情':'/mm/wx/bill/stock/delivery/detail/',
  '验收送货单详情':'/mm/wx/bill/stock/delivery/check/detail',
  '验收送货单':'/mm/wx/bill/stock/delivery/check',
  '新增送货单':'/mm/wx/bill/stock/delivery/add',
  '编辑送货单':'/mm/wx/bill/stock/delivery/edit',
  '删除送货单':'/mm/wx/bill/stock/delivery/delete/',
  '开始送货单':'/mm/wx/bill/stock/delivery/start/',
  '资金支付信息':'/mm/wx/payment/info/',
  '资金支付审核历史':'/mm/wx/payment/audit/list/',
  '资金支付签收':'/mm/wx/payment/audit/view',
  '资金支付审核':'/mm/wx/payment/audit/payment',
  '资金项目列表':'/mm/wx/payment/project/list',
  '资金列表':'/mm/wx/payment/list',
  '需用计划项目列表':'/mm/wx/plan/project/list',
  '需用计划列表':'/mm/wx/plan/list',
  '需用计划信息':'/mm/wx/plan/info/',
  '需用计划审核历史':'/mm/wx/plan/audit/list/',
  '需用计划下一个审批人':'/mm/wx/plan/getPlanNextApprover/',
  '需用计划审核':'/mm/wx/plan/audit/plan',
}

Vue.prototype.$url = url;