import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Register from '../views/Register'
import Password from '../views/Password'
import Index from '../views/index/Index'
import BillList from '../views/bill/BillList'
import BillDetail from '../views/bill/BillDetail'
import BillSingleDetail from '../views/bill/BillSingleDetail'
import AddStockDelivery from '../views/delivery/AddStockDelivery'
import EditStockDelivery from '../views/delivery/EditStockDelivery'
import CheckStockDelivery from '../views/delivery/CheckStockDelivery'
import StockDeliveryDetail from '../views/delivery/StockDeliveryDetail'
import PaymentList from '../views/payment/PaymentList'
import CheckPayment from '../views/payment/CheckPayment'
import PaymentDetail from '../views/payment/PaymentDetail'
import PlanList from '../views/plan/PlanList'
import PlanDetail from '../views/plan/PlanDetail'
import CheckPlan from '../views/plan/CheckPlan'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta:{
            title: '浩创置业集团服务平台',
            needLogin:false
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta:{
            title: '注册',
            needLogin:false
        }
    },
    {
        path: '/password',
        name: 'password',
        component: Password,
        meta:{
            title: '修改密码',
            needLogin:true
        }
    },
    {
        path: '/index',
        name: 'index',
        component: Index,
        meta:{
            title: '材料管理',
            needLogin:true
        }
    },
    {
        path: '/bill-list',
        name: 'bill-list',
        component: BillList,
        meta:{
            title: '送货通知单查询',
            needLogin:true
        }
    },
    {
        path: '/bill-detail',
        name: 'bill-detail',
        component: BillDetail,
        meta:{
            title: '送货通知单',
            needLogin:true
        }
    },
    {
        path: '/bill-single-detail',
        name: 'bill-single-detail',
        component: BillSingleDetail,
        meta:{
            title: '送货通知单详情',
            needLogin:true
        }
    },
    {
        path: '/add-stock-delivery',
        name: 'add-stock-delivery',
        component: AddStockDelivery,
        meta:{
            title: '送货单',
            needLogin:true
        }
    },
    {
        path: '/edit-stock-delivery',
        name: 'edit-stock-delivery',
        component: EditStockDelivery,
        meta:{
            title: '送货单',
            needLogin:true
        }
    },
    {
        path: '/check-stock-delivery',
        name: 'check-stock-delivery',
        component: CheckStockDelivery,
        meta:{
            title: '送货单',
            needLogin:true
        }
    },
    {
        path: '/stock-delivery-detail',
        name: 'stock-delivery-detail',
        component: StockDeliveryDetail,
        meta:{
            title: '送货单',
            needLogin:true
        }
    },
    {
        path: '/payment-list',
        name: 'payment-list',
        component: PaymentList,
        meta:{
            title: '资金支付审核',
            needLogin:true
        }
    },
    {
        path: '/check-payment',
        name: 'check-payment',
        component: CheckPayment,
        meta:{
            title: '资金支付审核',
            needLogin:true
        }
    },
    {
        path: '/payment-detail',
        name: 'payment-detail',
        component: PaymentDetail,
        meta:{
            title: '资金支付详情',
            needLogin:true
        }
    },
    {
        path: '/plan-list',
        name: 'plan-list',
        component: PlanList,
        meta:{
            title: '需用计划审批查询',
            needLogin:true
        }
    },
    {
        path: '/plan-detail',
        name: 'plan-detail',
        component: PlanDetail,
        meta:{
            title: '需用计划详情',
            needLogin:true
        }
    },
    {
        path: '/check-plan',
        name: 'check-plan',
        component: CheckPlan,
        meta:{
            title: '需用计划审批',
            needLogin:true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
